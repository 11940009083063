// ----------------------------------------------------------------------


const account = {
  displayName: 'Medi Inn Coaching',
  email: 'medi-inn@gmail.com',
  photoURL: '/static/mock-images/avatars/avatar_default.jpg',
};

export default account;
