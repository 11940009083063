import { get, isEmpty, set } from 'lodash';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
//API
import parse from 'html-react-parser';

// components
import Page from '../components/Page';
// sections
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { addMCQQustions, deleteMCQQustions, editExam, getExamDetail } from 'src/axiosLib/services/exam/admin';
import Question from './Question';
import { activeStatus, emptyQuestion, sessions } from 'src/util/helpers';
import { Icon } from '@iconify/react';
import moment from 'moment';
import { getAllBatches } from 'src/axiosLib/services/batch/admin';
import { getAllBranches } from 'src/axiosLib/services/branch/admin';
import { getAllSubjects } from 'src/axiosLib/services/subject/admin';
import config from '../util/config';
import { adminLevel } from '../axiosLib/services/institution/admin';
// ----------------------------------------------------------------------

export default function EditExam() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [examDetails, setExamDetails] = useState({});
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [editing, setEditing] = useState(-1);
  const [subjectModal, setSubjectModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [examInfo, setexamInfo] = useState({
    startTime: moment().format('yyyy-MM-DD hh:mm A'),
    endTime: moment().format('yyyy-MM-DD hh:mm A'),
    answerPublishTime: moment().format('yyyy-MM-DD hh:mm A'),
    session: 2024,
  });
  const [branches, setBranches] = useState([]);
  const [batches, setBatches] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [examInfoEditable, setExamInfoEditable] = useState(false);
  const [adminData, setAdminData] = useState({});

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const examId = urlParams.get('examId');

    getExamDetail(examId, (err, data) => {
      if (!err) {
        setExamDetails(data);
        const q = data.mcqContent ? data.mcqContent : [];
        setQuestions(q);
        const ans = q.map((obj) => new Set(obj.correctOptions));
        setAnswers(ans);
      }
    });

    getAllSubjects(1000, 0, (err, data) => {
      if (!err) {
        const allsubjects = data.subjects;
        const subjectDetails = allsubjects.map((subject) => {
          return { id: subject._id, name: subject.name + ' ' + subject.subjectCode };
        });
        setSubjects(subjectDetails);
        // console.log(subjectDetails);
      }

      adminLevel((error, data) => {
        if (!error) {
          setAdminData(get(data, 'admin'));
        } else {
          console.log(error);
        }
      });
    });

    getAllBranches(100, 0, (err, data) => {
      if (!err) {
        const branches = data.branches;
        const branchDetails = branches.map((branch) => {
          return { id: branch._id, branchName: branch.branchName };
        });
        setBranches(branchDetails);
        // console.log(branchDetails);
      }
    });

    getAllBatches(1000, 0, (err, data) => {
      if (!err) {
        const batches = data.batches;
        const batchDetails = batches.map((batch) => {
          return { id: batch._id, batchName: batch.batchName };
        });
        setBatches(batchDetails);
      }
    });
  }, []);

  useEffect(() => {
    if (!isEmpty(examDetails)) {
      setexamInfo({
        ...examInfo,
        examId: examDetails._id,
        name: examDetails.name,
        branchId: examDetails.branchId,
        batchId: examDetails.batchId,
        negativeMarks: examDetails.negativeMarks,
        marksPerQuestion: examDetails.marksPerQuestion,
        numberOfQuestions: examDetails.numberOfQuestions,
        subjectId: examDetails.subjectId,
        session: examDetails.session,
        duration: examDetails.duration,
        answerPublishTime: examDetails.answerPublishTime,
        endTime: examDetails.endTime,
        startTime: examDetails.startTime,
        teacher: examDetails.teacher,
        isMobileScanEnabled: examDetails.isMobileScanEnabled
      });
    }
  }, [examDetails]);

  const handleChange = (fieldName, event) => {
    if (fieldName === 'validTill') {
      setexamInfo({ ...examInfo, [fieldName]: moment(event.target.value).format('DD/MM/YYYY') });
    } else {
      setexamInfo({ ...examInfo, [fieldName]: event.target.value });
    }
  };

  const handleChange2 = (fieldName, event) => {
    const {
      target: { value },
    } = event;
    const branchNames = typeof value === 'string' ? value.split(',') : value;
    setexamInfo({
      ...examInfo,
      [fieldName]: typeof value === 'string' ? value.split(',') : value,
    });
  };

  const handleChange3 = (fieldName, event) => {
    const {
      target: { value },
    } = event;
    const name = typeof value === 'string' ? value.split(',') : value;
    setexamInfo({
      ...examInfo,
      [fieldName]: typeof value === 'string' ? value.split(',') : value,
    });
  };

  const deleteChip = (fieldName, chipToDelete) => {
    const values = get(examInfo, fieldName, []);
    setexamInfo({
      ...examInfo,
      [fieldName]: values.filter((value) => {
        return value !== chipToDelete;
      }),
    });
  };

  const getName = (fieldName, idTOSearch) => {
    // console.log(fieldName , idTOSearch );
    // console.log(idTOSearch);
    // console.log(typeof idTOSearch);
    if (typeof idTOSearch === 'object') {
      idTOSearch = get(idTOSearch, '_id');
    }
    if (fieldName === 'branch') {
      const selectedBranch = branches.filter((branch) => {
        return branch.id === idTOSearch;
      });
      return selectedBranch[0] ? selectedBranch[0].branchName : null;
    } else if (fieldName === 'batch') {
      const selectedBatch = batches.filter((batch) => {
        return batch.id === idTOSearch;
      });
      return selectedBatch[0] ? selectedBatch[0].batchName : null;
    } else if (fieldName === 'subject') {
      const selectedSubject = subjects.filter((subject) => {
        return subject.id === idTOSearch;
      });
      return selectedSubject[0] ? selectedSubject[0].name : null;
    }
  };

  const saveExamInfo = () => {
    if (examInfo.numberOfQuestions < questions.length) {
      const errormsg =
        'you have already set ' +
        questions.length +
        ' questions. delete some of them if you want to decrease the number';
      alert(errormsg);
      setexamInfo({
        ...examInfo,
        numberOfQuestions: questions.length,
      });
      return;
    }
    const val = get(examInfo, 'numberOfQuestions', 0) * get(examInfo, 'marksPerQuestion', 0);
    set(examInfo, 'totalMarks', val);
    editExam(examInfo, (err, axios_data) => {
      if (!err) {
        alert('Exam Details has been updated successfully.');
        window.location.reload();
      } else {
      }
    });
  };

  const saveMcq = (obj, ans) => {
    let tempQ = [];
    questions.map((q, idx) => tempQ.push(JSON.parse(JSON.stringify(q))));
    let tempAns = [...answers];

    if (editing === -1) {
      tempQ.push(obj);
      tempAns.push(ans);
    } else {
      tempQ[editing] = obj;
      tempAns[editing] = ans;
    }

    setQuestions(tempQ);
    setAnswers(tempAns);
    setEditing(-1);
  };

  const handleDelete = (idx) => {
    setShowLoader(true);
    let tempQ = [];
    questions.map((q, idx) => tempQ.push(JSON.parse(JSON.stringify(q))));
    let tempAns = [...answers];
    if (idx < parseInt(examInfo.numberOfQuestions)) {
      deleteMCQQustions(examDetails._id, { mcqId: questions[idx]._id }, (err, data) => {
        if (!err) {
          tempQ.splice(idx, 1);
          tempAns.splice(idx, 1);
          setQuestions(tempQ);
          setAnswers(tempAns);
          setShowLoader(false);
        } else {
          setShowLoader(false);
        }
      });
    } else {
      tempQ.splice(idx, 1);
      tempAns.splice(idx, 1);
      setQuestions(tempQ);
      setAnswers(tempAns);
      setShowLoader(false);
    }
  };

  const handleSendExam = () => {
    setShowLoader(true);
    let ans = [];
    // initialLength if we just wanna send the change
    for (let i = 0; i < answers.length; i++) {
      ans.push([...answers[i]]);
    }
    addMCQQustions(examDetails._id, { question: questions, answer: ans }, (err, data) => {
      if (!err) {
        setShowLoader(false);

        alert('Exam questions are saved successfully');

        window.location.reload();
      } else {
        console.log(err);

        alert('Could not upload exam questions. Some Error Occured');
      }
    });
  };

  return (
    <Page title="Edit Exam">
      <Container maxWidth="xl">
        <Card sx={{ width: '98%', marginBottom: '30px', border: '2px solid #800000' }}>
          <CardContent
            style={{
              backgroundColor: 'beige',
              color: '#800000',
              fontWeight: 'bold',
              fontSize: '20px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            Exam Details | CODE: {get(examDetails, 'code', '')}
          </CardContent>
        </Card>

        <Card sx={{ width: '98%', marginBottom: '30px', border: '2px solid #800000' }}>
          <CardContent
            style={{
              backgroundColor: 'beige',
              color: '#800000',
              fontWeight: 'bold',
              fontSize: '20px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <Button
                  style={{ backgroundColor: '#800000', color: 'white', padding: '10px 40px', marginRight: '10px' }}
                  onClick={() => {
                    let link = config.WEB_LINK + 'reg/publicExam?examId=' + get(examDetails, '_id', '');

                    alert(link);
                  }}
                >
                  Public Exam Link
                </Button>
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <Button
                  style={{ backgroundColor: '#800000', color: 'white', padding: '10px 40px' }}
                  onClick={() => {
                    let link = config.WEB_LINK + 'webapi/student/exam/qrExam?examCode=' + get(examDetails, 'code', '');

                    alert(link);
                  }}
                >
                  QR code Exam Link
                </Button>
              </Stack>
            </div>
          </CardContent>
        </Card>

        <div
          style={{
            width: '98%',
            marginBottom: '30px',
            border: '2px solid #800000',
            padding: '25px 10px',
            borderRadius: '20px',
          }}
        >
          <Stack spacing={3}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                name="name"
                label="Exam Name"
                value={get(examInfo, 'name', '')}
                style={{ width: '100%' }}
                onChange={(e) => {
                  handleChange('name', e);
                }}
                required
                disabled={!examInfoEditable}
              />
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <FormControl fullWidth required disabled={!examInfoEditable}>
                <InputLabel id="demo-multiple-chip-label">Branch</InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={get(examInfo, 'branchId', [])}
                  onChange={(e) => {
                    handleChange2('branchId', e);
                  }}
                  input={<OutlinedInput id="select-multiple-chip" label="Branch" />}
                  MenuProps={MenuProps}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip
                          key={typeof value === 'object' ? get(value, '_id') : value}
                          label={getName('branch', value)}
                          onDelete={() => {
                            examInfoEditable && deleteChip('branchId', value);
                          }}
                          style={{ zIndex: 10000 }}
                        />
                      ))}
                    </Box>
                  )}
                >
                  {branches.map((branch) => (
                    <MenuItem key={branch.branchName} value={branch.id}>
                      {branch.branchName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth required disabled={!examInfoEditable}>
                <InputLabel id="batch-level">Batch</InputLabel>
                <Select
                  labelId="batch-level"
                  id="batch-level"
                  multiple
                  value={get(examInfo, 'batchId', [])}
                  onChange={(e) => {
                    handleChange2('batchId', e);
                  }}
                  input={<OutlinedInput id="select-multiple-chip-batch" label="Batch" />}
                  MenuProps={MenuProps}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip
                          key={typeof value === 'object' ? get(value, '_id') : value}
                          label={getName('batch', value)}
                          onDelete={() => {
                            examInfoEditable && deleteChip('batchId', value);
                          }}
                          style={{ zIndex: 10000 }}
                        />
                      ))}
                    </Box>
                  )}
                >
                  {batches.map((batch) => (
                    <MenuItem key={batch.batchName} value={batch.id}>
                      {batch.batchName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <FormControl fullWidth required>
                <InputLabel id="sessiom-label">Mobile OMR Scan Enable</InputLabel>
                <Select
                  id="session-label"
                  disabled={get(adminData, 'roleSet', []).includes('MOBILE_OMR_SCAN') && examInfoEditable ? false : true}
                  value={get(examInfo, 'isMobileScanEnabled', 'false')}
                  label="Mobile OMR Scan Enabled"
                  onChange={(e) => {
                    handleChange('isMobileScanEnabled', e);
                  }}
                >
                  {activeStatus.map((option) => (
                    <MenuItem value={option} key={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                name="numberOfQuestions"
                label="Total Questions"
                value={get(examInfo, 'numberOfQuestions', '')}
                style={{ width: '100%' }}
                onChange={(e) => {
                  handleChange('numberOfQuestions', e);
                }}
                required
                disabled={!examInfoEditable}
              />

              <TextField
                name="marksPerQuestion"
                label="Marks Per Question"
                value={get(examInfo, 'marksPerQuestion', '')}
                style={{ width: '100%' }}
                onChange={(e) => {
                  handleChange('marksPerQuestion', e);
                }}
                disabled={!examInfoEditable}
              />

              <TextField
                name="negativeMarks"
                label="Negative Marks Per Question"
                value={get(examInfo, 'negativeMarks', '')}
                style={{ width: '100%' }}
                onChange={(e) => {
                  handleChange('negativeMarks', e);
                }}
                required
                disabled={!examInfoEditable}
              />
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                name="duration"
                label="Exam Duration in Minutes"
                value={get(examInfo, 'duration', '')}
                style={{ width: '100%' }}
                onChange={(e) => {
                  handleChange('duration', e);
                }}
                required
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                disabled={!examInfoEditable}
              />

              <FormControl fullWidth required disabled={!examInfoEditable}>
                <InputLabel id="sessiom-label">Session</InputLabel>
                <Select
                  id="session-label"
                  value={get(examInfo, 'session', 2024)}
                  label="Session"
                  onChange={(e) => {
                    handleChange('session', e);
                  }}
                >
                  {sessions.map((option) => (
                    <MenuItem value={option} key={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth required>
                <InputLabel id="sessiom-label">Teacher</InputLabel>
                <Select
                  id="session-label"
                  disabled={!examInfoEditable}
                  value={get(examInfo, 'teacher', '')}
                  label="Teacher"
                  onChange={(e) => {
                    handleChange('teacher', e);
                  }}
                >
                  {get(adminData, 'institutionId.teachers', '#')
                    .split('#')
                    .map((option) => (
                      <MenuItem value={option} key={option}>
                        {option}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <FormControl fullWidth required disabled={!examInfoEditable}>
                <InputLabel id="subject-level">Subject</InputLabel>
                <Select
                  labelId="subject-level"
                  id="subject-level"
                  multiple
                  value={get(examInfo, 'subjectId', [])}
                  onChange={(e) => {
                    handleChange3('subjectId', e);
                  }}
                  input={<OutlinedInput id="select-multiple-chip-batch" label="subject" />}
                  MenuProps={MenuProps}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip
                          key={typeof value === 'object' ? get(value, '_id') : value}
                          label={getName('subject', value)}
                          onDelete={() => {
                            examInfoEditable && deleteChip('subjectId', value);
                          }}
                          style={{ zIndex: 10000 }}
                        />
                      ))}
                    </Box>
                  )}
                >
                  {subjects.map((subject) => (
                    <MenuItem key={subject.name} value={subject.id}>
                      {subject.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs} style={{ width: '100%' }}>
                <DateTimePicker
                  label="Exam START Time"
                  name="startTime"
                  value={moment(get(examInfo, 'startTime')).format('yyyy-MM-DD hh:mm A')}
                  onChange={(newValue) => {
                    // console.log(newValue);
                    setexamInfo({ ...examInfo, startTime: new Date(newValue) });
                  }}
                  inputFormat="DD/MM/YYYY hh:mm A"
                  renderInput={(params) => <TextField {...params} />}
                  PopperProps={{
                    style: { zIndex: 10001 },
                  }}
                  disabled={!examInfoEditable}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs} style={{ width: '100%' }}>
                <DateTimePicker
                  label="Exam END Time"
                  name="endTime"
                  value={moment(get(examInfo, 'endTime')).format('yyyy-MM-DD hh:mm A')}
                  onChange={(newValue) => {
                    setexamInfo({ ...examInfo, endTime: new Date(newValue) });
                  }}
                  inputFormat="DD/MM/YYYY hh:mm A"
                  renderInput={(params) => <TextField {...params} />}
                  style={{ width: '100%' }}
                  PopperProps={{
                    style: { zIndex: 10001 },
                  }}
                  disabled={!examInfoEditable}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs} style={{ width: '100%' }}>
                <DateTimePicker
                  label="ANSWER Publish Time"
                  name="answerPublishTime"
                  value={moment(get(examInfo, 'answerPublishTime')).format('yyyy-MM-DD hh:mm A')}
                  onChange={(newValue) => {
                    setexamInfo({ ...examInfo, answerPublishTime: new Date(newValue) });
                  }}
                  inputFormat="DD/MM/YYYY hh:mm A"
                  renderInput={(params) => <TextField {...params} />}
                  PopperProps={{
                    style: { zIndex: 10001 },
                  }}
                  disabled={!examInfoEditable}
                />
              </LocalizationProvider>
            </Stack>
          </Stack>

          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
            {examInfoEditable ? (
              <div>
                <Button
                  style={{ backgroundColor: '#800000', color: 'white', padding: '10px 40px', marginRight: '10px' }}
                  onClick={() => {
                    setExamInfoEditable(!examInfoEditable);
                    saveExamInfo();
                  }}
                >
                  Save
                </Button>
                <Button
                  style={{ backgroundColor: '#800000', color: 'white', padding: '10px 40px' }}
                  onClick={() => {
                    setExamInfoEditable(!examInfoEditable);
                    setexamInfo({
                      ...examInfo,
                      name: examDetails.name,
                      branchId: examDetails.branchId,
                      batchId: examDetails.batchId,
                      negativeMarks: examDetails.negativeMarks,
                      marksPerQuestion: examDetails.marksPerQuestion,
                      numberOfQuestions: examDetails.numberOfQuestions,
                      subjectId: examDetails.subjectId,
                      session: examDetails.session,
                      duration: examDetails.duration,
                      answerPublishTime: examDetails.answerPublishTime,
                      endTime: examDetails.endTime,
                      startTime: examDetails.startTime,
                      teacher: examDetails.teacher,
                      isMobileScanEnabled: examDetails.isMobileScanEnabled
                    });
                  }}
                >
                  Cancel
                </Button>
              </div>
            ) : (
              <Button
                style={{ backgroundColor: '#800000', color: 'white', padding: '10px 40px' }}
                onClick={() => {
                  setExamInfoEditable(!examInfoEditable);
                }}
              >
                Edit Exam Details
              </Button>
            )}
          </div>
        </div>

        <Card sx={{ width: '98%', marginBottom: '30px', border: '2px solid #800000' }}>
          <CardContent
            style={{
              backgroundColor: 'beige',
              color: '#800000',
              fontWeight: 'bold',
              fontSize: '20px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            Create MCQs
          </CardContent>
        </Card>

        <Card sx={{ width: '98%', marginBottom: '30px', border: '2px solid #800000' }}>
          <CardContent
            style={{
              backgroundColor: 'beige',
              color: '#800000',
              fontWeight: 'bold',
              fontSize: '20px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              style={{ backgroundColor: 'blue', color: 'white', padding: '10px 40px', alignItems: 'center' }}
              onClick={() => setSubjectModal(true)}
            >
              Equation & Symbols
            </Button>
          </CardContent>
        </Card>

        {examInfo.numberOfQuestions > questions.length ? (
          <Question tempQuestion={JSON.parse(JSON.stringify(emptyQuestion))} answers={new Set()} saveMcq={saveMcq} />
        ) : (
          <div />
        )}

        {questions.map((obj, idx) =>
          editing !== idx ? (
            <div>
              <Card sx={{ width: '98%', marginBottom: '30px', border: '2px solid #800000' }}>
                <CardContent>
                  <div style={{ display: 'flex', width: '100%' }}>
                    <div style={{ width: '10%', margin: '10px' }}>
                      <div
                        style={{
                          width: 35,
                          backgroundColor: '#800000',
                          textAlign: 'center',
                          borderRadius: '50%',
                          color: 'white',
                        }}
                      >
                        {idx + 1}
                      </div>
                    </div>
                    <div style={{ fontSize: '20px', width: '80%', marginTop: '5px' }}>{parse(obj.question)}</div>
                    <div style={{ width: '8%' }}>
                      <Icon
                        icon="material-symbols:edit-sharp"
                        style={{ cursor: 'pointer', marginRight: 10, fontSize: '25px', color: 'blue' }}
                        onClick={() => setEditing(idx)}
                      />
                      <Icon
                        icon="ic:round-delete"
                        style={{ cursor: 'pointer', fontSize: '25px', color: 'red' }}
                        onClick={() => {
                          handleDelete(idx);
                        }}
                      />
                    </div>
                  </div>

                  {obj.options.map((opt, jdx) => (
                    <div style={{ display: 'flex', width: '100%' }}>
                      <div style={{ width: '10%', margin: '10px' }}>
                        <Checkbox
                          checked={answers[idx].has(jdx)}
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      </div>
                      <div style={{ width: '94%', display: 'flex', alignItems: 'center' }}>{parse(opt.title)}</div>
                    </div>
                  ))}
                </CardContent>
              </Card>
            </div>
          ) : (
            <Question key={idx} tempQuestion={questions[idx]} answers={answers[idx]} saveMcq={saveMcq} />
          )
        )}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {showLoader ? (
            <CircularProgress color="inherit" />
          ) : (
            <Button
              style={{ backgroundColor: '#800000', color: 'white', padding: '10px 40px' }}
              onClick={() => {
                handleSendExam();
              }}
            >
              Save Exam Questions
            </Button>
          )}
        </div>

        {/* EQUATION MODAL*/}
        <div>
          <Dialog open={subjectModal} onClose={() => setSubjectModal(false)}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
              {' '}
            </Stack>
            <DialogTitle>COMMON</DialogTitle>
            <DialogContent>° π ∇ ∠ △ θ α β γ</DialogContent>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
              {' '}
            </Stack>

            <DialogTitle>MATH</DialogTitle>
            <DialogContent>° ∞ ∑ ∏ ⋀ ⋂ ⋃ π ∇ √ ∫ ∮ ∂ ∇ ± ∅ ∠ △ □ ∉ ⊇ ⊆ ⊂ ≠ ≈ ≅ ⊗ ∀ ∃ ⊥ ⊢ ⊨ ∝</DialogContent>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
              {' '}
            </Stack>

            <DialogTitle>GREEK</DialogTitle>
            <DialogContent>α β γ Γ δ Δ ɛ ζ η θ Θ ϑ ι κ λ Λ μ ξ π Π ρ σ Σ τ υ ϕ Φ φ χ ψ Ψ ω Ω</DialogContent>

            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
              {' '}
            </Stack>
            <DialogTitle>ARROW</DialogTitle>
            <DialogContent>↑ ↓ → ↣ ↠ ⤖ ↦ ← ↔ ⇒ ⇐ ⇔</DialogContent>
          </Dialog>
        </div>
      </Container>
    </Page>
  );
}
