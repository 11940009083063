import { getReqAuth, postReqAuth } from '../../api';

// 1. Create New Exam
export const sendSMS = (data, cb) => postReqAuth("/webapi/institute/sms/send/single", data, "", cb);

export const sendBulkSMS = (data, cb) => postReqAuth("/webapi/institute/sms/send/bulk", data, "", cb);

export const sendBulkSMSToBatch = (data, cb) => postReqAuth("/webapi/institute/sms/send/batch/bulk", data, "", cb);

export const sendSMSStudents = (data, cb) => postReqAuth("/webapi/institute/sms/send/students", data, "", cb);

// Get all SMS
export const getAllSMS = (limit, page, cb) => postReqAuth("/webapi/institute/sms/get/all", {limit, page}, "", cb);

export const getSMSUsage = (data, cb) => postReqAuth("/webapi/institute/sms/get/usage", data, "", cb);

// Get all SMS
export const getRemainingSMS = (cb) => getReqAuth("/webapi/institute/sms/get/remaining", {}, "", cb);

// Get all SMS
export const deleteSMS = (id, cb) => getReqAuth("/webapi/institute/sms/delete/", {}, id, cb);

export const uploadExcelForSMS = (data, cb) => postReqAuth("/webapi/institute/dynamicSms/uploadExcel", data, "", cb);

export const sendDynamicSMS = (data, cb) => postReqAuth("/webapi/institute/dynamicSms/sendDynamicSms", data, "", cb);
