import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import UserDashboardLayout from './userLayout/dashboard';
//
import UserCheckExamAnswer from './pages/UserCheckExamAnswer';
import UserAfterSubmit from './pages/UserAfterSubmit';
import UserAfterSubmitPublicExam from './pages/UserAfterSubmitPublicExam';
import EditExam from './pages/EditExam';
import UserExamList from './pages/UserExamList';
import UserLogin from './pages/UserLogin';
import UserParticipateExam from './pages/UserParticipateExam';
import UserParticipatePublicExam from './pages/UserParticipatePublicExam';
import RegisterForPublicExam from './pages/RegisterForPublicExam';
import AdminLogin from './pages/AdminLogin';
import Institute from './pages/Institute';
import ExamResult from './pages/ExamResult';
import ExamDetails from './pages/ExamDetails';
import OnlineExamList from './pages/OnlineExamList';
import NewStudents from './pages/NewStudents';
import CourseEnrollment from './pages/CourseEnrollment';
import SmsSystem from './pages/SmsSystem';
import BatchSummary from './pages/BatchSummary';
import DynamicSmsSystem from './pages/DynamicSms';
import User from './pages/User';
import Payment from './pages/Payment';
import BatchWisePaymentSummary from './pages/BatchWisePaymentSummary';
import BatchWisePaymentDetails from './pages/BatchWisePaymentDetails';
import Home from './pages/Home';
import Login from './pages/Login';
import Invoice from './pages/Invoice';
import NotFound from './pages/Page404';
import Register from './pages/Register';

import DashboardApp from './pages/DashboardApp';
import StudentInfo from './pages/StudentInfo';
import UserResult from './pages/UserResult';
import SwitchInstituteApp from './pages/SwitchInstituteApp';
import OnlineMobileScannableExamList from './pages/OnlineMobileScannableExamList';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'switchInstitute', element: <SwitchInstituteApp /> },
        { path: 'user', element: <User /> },
        { path: 'institute', element: <Institute /> },
        { path: 'unverified/students', element: <NewStudents /> },
        { path: 'course_enrollments', element: <CourseEnrollment /> },
        { path: 'register_student', element: <StudentInfo /> },
        { path: 'onlineExam', element: <OnlineExamList /> },
        { path: 'onlineExam/mobileScan', element: <OnlineMobileScannableExamList /> },
        { path: 'batchSummary', element: <BatchSummary /> },
        { path: 'smsSystem', element: <SmsSystem /> },
        { path: 'dynamicSmsSystem', element: <DynamicSmsSystem /> },
        { path: 'examDetails', element: <ExamDetails /> },
        { path: 'examResult', element: <ExamResult /> },
        { path: 'editExam', element: <EditExam /> },
        { path: 'payment', element: <Payment /> },
        { path: 'batchWisePaymentSummary', element: <BatchWisePaymentSummary /> },
        { path: 'batchWisePaymentDetails', element: <BatchWisePaymentDetails /> },
      ],
    },
    {
      path: '/user',
      element: <UserDashboardLayout />,
      children: [
        { path: 'profile', element: <UserResult /> },
        { path: 'currentExams', element: <UserExamList /> },
        { path: 'checkAnswer', element: <UserCheckExamAnswer /> },
        { path: 'afterSubmit', element: <UserAfterSubmit /> },
      ],
    },
    {
      path: '/home',
      element: <Home />,
    },
    {
      path: 'admin/login',
      element: <AdminLogin />,
    },
    {
      path: 'user/login',
      element: <UserLogin />,
    },
    {
      path: 'user/exam/participate',
      element: <UserParticipateExam />,
    },
    {
      path: 'reg/publicExam',
      element: <RegisterForPublicExam />,
    },
    {
      path: 'publicExam',
      element: <UserParticipatePublicExam />,
    },
    {
      path: 'publicExam/score',
      element: <UserAfterSubmitPublicExam />,
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'invoice',
      element: <Invoice />,
    },
    {
      path: 'user/register',
      element: <Register />,
    },
    {
      path: '/',
      // element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/home" /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
