import { get, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';

// material
import { Button, Card, CardContent, Checkbox, CircularProgress, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import { finishPublicExam, getPublicExamDetails, getServerTimingForPublicExam } from 'src/axiosLib/services/exam/user';
import Countdown from 'react-countdown';
import { Icon } from '@iconify/react';
// mock
// import USERLIST from '../_mock/user';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export default function UserParticipateExam() {
  const navigate = useNavigate();

  const [examDetails, setExamDetails] = useState({});
  const [timerTime, setTimerTime] = useState(Date.now() + 10000 * 60);

  const [questions, setQuestions] = useState([]);
  const [answer, setAnswer] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  const [answerAttempt, setAnswerAttept] = useState(0);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const examId = urlParams.get('examId');
    let userData = JSON.parse(localStorage.getItem('publicExamUserData'));

    let temp = {
      userId: userData._id,
      examId: examId,
    };

    getServerTimingForPublicExam('temp', (error, timeData) => {
      if (!error) {
        let data = JSON.parse(localStorage.getItem('examData'));

        console.log('main data', data);

        // if (new Date(data.examStartTime) <= new Date(timeData.serverTime)) {
        if (!data ||  !data.examData) {
          getPublicExamDetails(temp, (error, examData) => {
            if (!error) {
              data = examData;

              setExamDetails(data.examData);
              setQuestions(data.examData.mcqContent);
              setAnswer(data.examData.mcqContent.map(() => new Set()));
            } else {
              console.log(error);
              alert('Exam time has ended or you have already participated in exam.');
            }
          });
          // }
        }
      } else {
        console.log(error, '------------------------------');
      }
    });
  }, []);

  useEffect(() => {
    if (!isEmpty(examDetails))
      setTimerTime(
        Date.now() +
          Math.min(timeDiffinMinutes(examDetails.startTime, examDetails.endTime), examDetails.duration * 60) * 1000
      );
  }, [examDetails]);

  const handleCheckBox = (idx, jdx) => {
    let attempted = 0;
    let tempAns = [...answer];

    if (examDetails.multipleOptions) {
      // uncheck the box that has already been checked
      if (tempAns[idx].has(jdx)) {
        tempAns[idx].delete(jdx);
        if (tempAns[idx].size === 0) attempted--;
      }
      // else assign
      else {
        tempAns[idx].add(jdx);

        // attempted
        if (tempAns[idx].size === 1) attempted++;
      }
    } else {
      if (tempAns[idx].size) {
        tempAns[idx].clear();
        tempAns[idx].add(jdx);
      } else tempAns[idx].add(jdx);
    }

    setAnswerAttept(answerAttempt + attempted);

    setAnswer(tempAns);
  };

  // useEffect(() => {console.log(answerAttempt)}, [answerAttempt]);

  const handlePublishAnswer = () => {
    setShowLoader(true);
    let ans = [];
    for (let i = 0; i < answer.length; i++) {
      ans.push([...answer[i]]);
    }

    console.log(ans);

    let user = JSON.parse(localStorage.getItem('publicExamUserData'));

    console.log(user);

    finishPublicExam(
      examDetails._id,
      { answer: ans, mcq: examDetails.mcqContent.map((m) => m._id), userId: user._id },
      (err, res) => {
        if (!err) {
          // this.setState({result: res, showResults: true});
          localStorage.removeItem('examData');
          setShowLoader(false);

          navigate('/publicExam/score?examId=' + get(examDetails, '_id'), { replace: true });
        } else {
        }
      }
    );
    // }
  };

  const timeDiffinMinutes = (start, end) => {
    const diffInMilliSeconds = Math.abs(new Date(end) - new Date(start));
    const seconds = Math.floor(diffInMilliSeconds / 1000).toFixed(2);
    return seconds;
  };

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      handlePublishAnswer();
      return <div></div>;
    } else {
      // Render a countdown
      return (
        <span>
          {hours}:{minutes}:{seconds}
        </span>
      );
    }
  };

  return (
    <Page title="Participate Exam" sx={{ backgroundImage: 'linear-gradient(60deg, #FF6666, #E6E6FA)' }}>
      <Container maxWidth="xl" sx={{ paddingTop: '20px', paddingBottom: '20px' }}>
        {/* <Card sx={{width: '100%', marginBottom: '30px', border: '2px solid #800000', marginTop: '20px' }}>
            <CardContent style={{backgroundColor: 'beige', color: '#800000', fontWeight: 'bold', fontSize: '20px'}}>
              <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                <Typography variant="h2">
                    {examDetails.name}
                </Typography>
                </div>
            </CardContent>
        </Card> */}
        <Card sx={{ width: '98%', marginBottom: '30px', border: '2px solid #800000' }}>
          <CardContent style={{ backgroundColor: 'beige', color: '#800000', fontWeight: 'bold', fontSize: '20px' }}>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <Typography variant="h2">{examDetails.name}</Typography>
              <Typography variant="h5">{/* {`Batches: ${batchName}`} */}</Typography>
              <Typography variant="h5">
                {/* {`Subjects: ${examDetails.subjectId? examDetails.subjectId.map((o) => o.name).join("; "): ''}`} */}
              </Typography>
              <Typography variant="h6">
                {`[ প্রতিটি প্রশ্নের জন্য ${examDetails.marksPerQuestion} নম্বর আছে । প্রতিটি ভুল উত্তরের  জন্য ${examDetails.negativeMarks} নম্বর কাঁটা হবে | সর্বমোট ${examDetails.totalMarks} ]`}
              </Typography>
            </div>
          </CardContent>
        </Card>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            flexDirection: 'row',
            // position: '-webkit-sticky',
            position: 'sticky',
            top: '10px',
            zIndex: '1000',
          }}
        >
          <Card sx={{ width: '30%', marginBottom: '30px', border: '2px solid #800000' }}>
            <CardContent style={{ backgroundColor: 'beige', color: '#800000', fontWeight: 'bold', fontSize: '20px' }}>
              {!isEmpty(examDetails) && (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                  <Icon
                    icon="material-symbols:nest-clock-farsight-analog-outline-rounded"
                    style={{ fontSize: '55px' }}
                  />
                  <Countdown date={timerTime} renderer={renderer} />
                </div>
              )}
            </CardContent>
          </Card>

          <Card sx={{ width: '30%', marginBottom: '30px', border: '2px solid #800000' }}>
            <CardContent style={{ backgroundColor: 'beige', color: '#800000', fontWeight: 'bold', fontSize: '20px' }}>
              {!isEmpty(examDetails) && (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                  <Typography variant="h5">
                    {answerAttempt} / {get(examDetails, 'numberOfQuestions', 0)}
                  </Typography>
                </div>
              )}
            </CardContent>
          </Card>
        </div>

        {questions.map((obj, idx) => (
          <div>
            <Card sx={{ width: '98%', marginBottom: '30px', border: '2px solid #800000' }}>
              <CardContent>
                <div style={{ display: 'flex', width: '100%' }}>
                  <div style={{ width: '10%', margin: '10px' }}>
                    <div
                      style={{
                        width: 35,
                        backgroundColor: '#800000',
                        textAlign: 'center',
                        borderRadius: '50%',
                        color: 'white',
                      }}
                    >
                      {idx + 1}
                    </div>
                  </div>
                  <div style={{ fontSize: '20px', width: '80%', marginTop: '5px' }}>{parse(obj.question)}</div>
                </div>

                {obj.options.map((opt, jdx) => (
                  <div style={{ display: 'flex', width: '100%' }}>
                    <div style={{ width: '10%', margin: '10px' }}>
                      <Checkbox
                        // checked={answers[idx].has(jdx)}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        onClick={() => handleCheckBox(idx, jdx)}
                      />
                    </div>
                    <div style={{ width: '94%', display: 'flex', alignItems: 'center' }}>{parse(opt.title)}</div>
                  </div>
                ))}
              </CardContent>
            </Card>
          </div>
        ))}

        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
          {showLoader ? (
            <CircularProgress color="inherit" />
          ) : (
            <Button
              style={{ backgroundColor: '#800000', color: 'white', padding: '10px 40px' }}
              onClick={() => {
                handlePublishAnswer();
              }}
            >
              Finish Exam
            </Button>
          )}
        </div>
      </Container>
    </Page>
  );
}
