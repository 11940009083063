import axios from 'axios';
import config from '../../../util/config';
import showErr from '../../api/errorAxios';
import { getReqAuth, postReq, postReqAuth } from '../../api';

// 1. Logging in on username LOG
// setting up mock data
export const loginUsername = (data, cb) => postReq("/webapi/institute/auth/login", data, "", cb, true);

const signupUsername = (data, cb) => postReq("/webapi/institute/auth/register", data, "", cb);

// 2. Change password on password LOG
const changePasswordOnPassword = (oldPassword, newPassword, cb) =>
	postReqAuth("/api/auth/changepassword/password", {oldPassword, newPassword}, "", cb);

const getProfile = (cb) => getReqAuth("/webapi/institute/auth/profile", {}, "", cb);

export const logout = (cb) => {
	const token = localStorage.getItem("jwtToken");
	if (token) {
		axios.defaults.headers.common["Authorization"] = token;
		axios
			.get(config.BASE_API_URL + "/webapi/institute/auth/logout")
			.then((res) => {
				localStorage.removeItem("jwtToken");
				localStorage.removeItem("user");
				localStorage.removeItem("type");
				delete axios.defaults.headers.common["Authorization"];
				cb(null, {});
			})
			.catch((err) => {
				showErr(err, cb);
			});
	} else {
		cb("Not authenticated", null);
	}
};

const logoutAll = (cb) => {
	const token = localStorage.getItem("jwtToken");
	if (token) {
		axios.defaults.headers.common["Authorization"] = token;
		axios
			.get(config.BASE_API_URL + "/webapi/institute/auth/logout/all")
			.then((res) => {
				localStorage.removeItem("jwtToken");
				localStorage.removeItem("user");
				localStorage.removeItem("type");
				delete axios.defaults.headers.common["Authorization"];
				cb(null, {});
			})
			.catch((err) => {
				showErr(err, cb);
			});
	} else {
		cb("Not authenticated", null);
	}
};

// 3. User list
const getUserList = (limit, page, cb) =>
	getReqAuth("/webapi/institute/auth/list/all", {limit, page}, "", cb);

const verifyAdmin = (id, cb) => getReqAuth("/webapi/institute/verify/", {}, id, cb);

const blockAdmin = (id, cb) => getReqAuth("/webapi/institute/block/", {}, id);

const setPriviledge = (id, data, cb) => postReqAuth("/webapi/institute/set/priviledge/", data, id, cb);

export default {
	signupUsername,
	changePasswordOnPassword,
	getProfile,
	logout,
	logoutAll,
	verifyAdmin,
	blockAdmin,
	getUserList,
	setPriviledge,
};
