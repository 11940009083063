module.exports = {
  // BASE_API_URL: 'http://192.168.212.133:5000',

  // BASE_API_URL: "https://drarif.lexbd.online",
  // VENDOR_1: "Dr. Arif Bio Special Batch",
  // VENDOR_2: "Asad English Batch",
  // VENDOR_BANGLA: "ডাঃ আরিফ",
  // IMAGE_BASE_URL: "/img/arif/logo2.jpg",
  // TEACHER: "Dr. Arif Bio#Asad English",
  // IS_PUBLIC: "true",
  // INVOICE_FOOTER_NOTE: "Thank you for your payment",
  // PAYMENT_ACCESS: "ALL",
  // WEB_LINK: "https://www.drarifmedicalbatch.com/",

  BASE_API_URL: "https://drasif.lexbd.online",
  VENDOR_1: 'Dr. Asif Medical Private Batch',
  VENDOR_2: 'SIZER Special English & GK Batch',
  VENDOR_BANGLA: 'ডাঃ আসিফ',
  IMAGE_BASE_URL: '/static/logo.webp',
  TEACHER: 'Dr. Asif#Kabir Sizer',
  IS_PUBLIC: 'false',
  INVOICE_FOOTER_NOTE:
    '* অঙ্গীকারনামাঃ আমি ডাঃ আসিফ স্যারের প্রতি পূর্ণ আস্থা নিয়ে মেডিকেল কলেজ ভর্তি পরীক্ষার প্রস্তুতির জন্য ডাঃ আসিফ মেডিকেল প্রাইভেট ব্যাচে ভর্তি হয়েছি। আমার ভর্তি কোন অবস্থাতেই বাতিলযোগ্য নয় এবং আমার প্রদানকৃত অর্থ ফেরত যোগ্য নয়। আমি ডাঃ আসিফ মেডিকেল প্রাইভেট ব্যাচ-এর সমস্ত ক্লাস করতে এবং সমুদয় পরীক্ষা দিতে অঙ্গীকারবদ্ধ। রেজাল্টের পর আমার সাফল্য ও কৃতিত্বের জন্য ডাঃ আসিফ মেডিকেল প্রাইভেট ব্যাচ-এর কর্তৃপক্ষ তাদের প্রসপেক্টাসে আমার নাম, ঠিকানা, ছবি, ফোন/মোবাইল নম্বর ইত্যাদি প্রকাশ করলে আমি কোনরূপ আপত্তি প্রকাশ করবো না। ভর্তির সময় ভর্তি ফি এর কোন অংশ বাকী থাকলে তাহা ভর্তির তারিখ থেকে দুই সপ্তাহের মধ্যে পরিশোধ না করলে আমার ভর্তি বাতিল হয়ে যাবে।',
  PAYMENT_ACCESS: 'RES',
  WEB_LINK: 'https://www.drasifbatch.com/',
  IMAGE_HERO: '/img/asif/hero.jpg',

  // BASE_API_URL: "https://creative.lexbd.online",
  // VENDOR_1: "Creative Admission",
  // VENDOR_2: "Academic Care",
  // VENDOR_BANGLA: "ক্রিয়েটিভ একাডেমিক এন্ড এডমিশন কেয়ার",
  // IMAGE_BASE_URL: "/static/logo_creative.jpg",
  // TEACHER: "A#B",
  // IS_PUBLIC: "true",
  // INVOICE_FOOTER_NOTE: "Thank you for your payment",
  // PAYMENT_ACCESS: "ALL",
  // WEB_LINK: "https://www.creativeadmissioncare.com/",

  // BASE_API_URL: "https://drtarim.lexbd.online",
  // VENDOR: "3 Doctors Medical Batch",
  // VENDOR_BANGLA: "থ্রি ডক্টরস",
  // IMAGE_BASE_URL: "/tarim",

  ENV: process.env.NODE_ENV,
  APP_URL: process.env.REACT_APP_HOME,
  FB_APP_ID: process.env.REACT_APP_FB_APP_ID,
  FB_APP_SECRET: process.env.REACT_APP_FB_APP_SECRET,
  GA_TRACKING_ID: process.env.REACT_APP_GA_TRACKING_ID,
  SITE_URL: process.env.REACT_APP_SITE_URL,
  SHORT_SITE_TITLE: 'Exam Site Lexbd',
  SITE_TITLE: 'Exam Site Lexbd',
  SITE_DESCRIPTION: 'Exam Site Lexbd',
  SITE_IMAGE: 'Exam Site Lexbd',
};
