import { getReqAuth, postReqAuth } from '../../api';

// 1. Create New Branch
export const createSubject = (data, cb) =>
	postReqAuth("/webapi/institute/subject/create", data, "", cb);

// 2. Get All Branches
export const getAllSubjects = (limit, page, cb) =>
	postReqAuth("/webapi/institute/subject/get/all", {limit, page}, "", cb);

// 3. Get Branch Detail
export const getSubjectDetail = (id, cb) =>
	getReqAuth("/webapi/institute/subject/get/detail/", {}, id, cb);

// 4. Delete subject
export const deleteSubject = (id, cb) => getReqAuth("/webapi/institute/subject/delete/", {}, id, cb);
