import { getReqAuth, postReqAuth } from '../../api';

// 1. Create New Exam
export const getBatchWisePaymentSummary = (data, cb) => 
	postReqAuth("/webapi/institute/payment/batch/summary", data, "", cb);

export const getAllPayments = (data, cb) => 
	postReqAuth("/webapi/institute/payment/allPayments", data, "", cb);

export const getAllPaymentsByBatch = (data, cb) => 
	postReqAuth("/webapi/institute/payment/batch/details/userPayments", data, "", cb);

export const getOverAllPaymentStatus = (data, cb) => 
	postReqAuth("/webapi/institute/payment/overall/collected", data, "", cb);

export const getOverAllPaymentStatusExcel = (data, cb) => 
	postReqAuth("/webapi/institute/payment/overall/collected/excel", data, "", cb);
	
export const getAllPaymentsByBatchForExcel = (data, cb) => 
	postReqAuth("/webapi/institute/payment/batch/details/userPayments/excel", data, "", cb);

export const getAllPaymentsExcel = (data, cb) => 
	postReqAuth("/webapi/institute/payment/allPayments/excel", data, "", cb);

export const removePaymentHistory = (data, cb) => 
	postReqAuth("/webapi/institute/payment/removeHistory", data, "", cb);

export const setPaymentInDb = (data, cb) => 
	postReqAuth("/webapi/institute/payment/crateOrUpdate", data, "", cb);

export const getUserPayments = (id, cb) =>
	getReqAuth("/webapi/institute/payment/userPayments/", {}, id, cb);

export const printInvoice = (id, cb) =>
	getReqAuth("/webapi/institute/payment/print/", {}, id, cb);

