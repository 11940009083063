// import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import {
  Box,
  Button,
  CardActionArea,
  CardActions,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Icon } from '@iconify/react';
import { get } from 'lodash';
// components
import Page from '../components/Page';
import config from '../util/config';
import DrawerAppBar from './DrawerAppBar';
import PageFooter from './footer';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { achivements, BOOKS, borderColors, colors, contactInfo, reviews } from './informationHomePage';
import React, { useEffect, useState } from 'react';
import './style.css';
import { getAllCourses } from 'src/axiosLib/services/course/user';
import { enrollCourse } from 'src/axiosLib/services/courseEnrollment/user';
import { sessions } from '../util/helpers';

// const tooltipRef = useRef(<div></div>);
// const handleCopy = (textToCopy) => {
//     // Create a text area element to hold the text to be copied
//     const textArea = document.createElement('textarea');
//     textArea.value = textToCopy;

//     // Append the text area to the tooltip
//     tooltipRef.current.appendChild(textArea);

//     // Select the text in the text area
//     textArea.select();

//     // Copy the selected text to the clipboard
//     document.execCommand('copy');

//     // Remove the text area from the tooltip
//     tooltipRef.current.removeChild(textArea);

//     // Optionally show a notification or do something else to indicate that the text is copied
//     console.log('Text copied to clipboard!');
//   };

const settings = {
  dots: true,
  infinite: true,
  speed: 300,
  slidesToShow: 2,
  autoplay: true,
  slidesToScroll: 2,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ],
};

const courseCarasoulSettings = {
  dots: true,
  infinite: true,
  speed: 100,
  slidesToShow: 3,
  slidesToScroll: 3,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ],
};

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  // padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Home() {
  const [allCourses, setAllCourses] = useState([{}]);

  const [courseRegModal, setCourseRegModal] = useState(false);

  const [courseRegData, setCourseRegData] = useState();

  const openCourseDetailsModal = (row) => {
    const temp = {
      courseId: get(row, '_id', ''),
      courseTitle: get(row, 'title', ''),
      courseImage: get(row, 'image', ''),
    };

    setCourseRegData(temp);

    setCourseRegModal(true);
  };

  const handleCourseRegDataChange = (fieldName, event) => {
    // console.log(event.target.value);
    setCourseRegData({ ...courseRegData, [fieldName]: event.target.value });
  };

  const getCourses = () => {
    getAllCourses({}, (err, data) => {
      if (!err) {
        // console.log(data);

        setAllCourses(data);
      }
    });
  };

  const regCourse = () => {
    enrollCourse(courseRegData, (err, data) => {
      if (!err) {
        setCourseRegModal(false);

        alert('Successfully Registered');
      } else {
        let errorMessage = '  *****  Please **SCROLL PAGE** to fillup all fields';

        for (const errId in err) {
          errorMessage = errorMessage + '\n  ==> ' + err[errId];
        }

        errorMessage = errorMessage.slice(2);
        alert(errorMessage);
      }
    });

    // console.log(studentInfo)
  };

  const handleSessionFilter = (e) => {
    setCourseRegData({ ...courseRegData, ['session']: e.target.value.split(' ')[0] });
  };

  useEffect(() => {
    getCourses();
  }, []);

  const handleCardClick = (info) => {
    if (get(info, 'media') == 'Phone' || get(info, 'media') == 'Gmail') {
      alert(get(info, 'info'));
    } else {
      window.location.href = get(info, 'link');
    }
  };
  return (
    <Page title="Home" style={{ backgroundColor: 'white' }}>
      <DrawerAppBar />
      <Container>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
          <div style={{ width: '100%' }}>
            <h2>
              <b>সাদা এ্যাপ্রোনের স্বপ্ন পূরণে</b>
            </h2>
            <h2 style={{ color: '#FF675A' }}>
              <b>ডাঃ আসিফ ও K@bir Sizer ভাইয়ার</b>
            </h2>
            <p>
              <strong>Medi Inn</strong> পরিবারের সাথে শুরু হোক তোমার পথচলা। Online Medical Admission Test প্রিপারেশনে
              দেশ সেরা শিক্ষক ডাঃ আসিফ ও K@bir Sizer স্যারের দেশ সেরা প্লাটফর্ম <strong>Medi Inn</strong>।সপ্তাহে ৬ দিন
              লাইভ ক্লাস ও নিজস্ব ওয়েবসাইটে সম্পূর্ন মেডিকেল স্ট্যান্ডার্ড প্রশ্নপত্রে পরীক্ষা নেওয়া হয়।৩ দিন Physics,
              Chemistry & Biology এর পাশাপাশি বাকী ৩ দিন English & General Knowledge এর উপর ক্লাস নেওয়া হয়।মেডিকেল
              এ্যাডমিশনের প্রতিটি ক্লাস ডাঃ আসিফ এবং K@bir Sizer ভাইয়া নিয়ে থাকে।মেডিকেল এ্যাডমিশনে ১০০% প্রিপারেশনে{' '}
              <strong>Medi Inn</strong> এর স্টুডেন্ট অন্যান্যদের তুলনায় সর্বদা একধাপ এগিয়ে থাকে আলহামদুলিল্লাহ।
            </p>
          </div>
          <div style={{ width: '100%' }}>
            {/* <img 
              src="https://img.freepik.com/free-vector/doctor-character-background_1270-84.jpg?w=740&t=st=1689343690~exp=1689344290~hmac=4ff5d48daf3d883cb914cd47510966877dbf1d3f1bb3ebb8bdb50d2709b0d64d"
              width="500px"
              height="400px"
              style={{marginLeft: 'auto', marginRight: '0'}}></img> */}
            <Box
              component="img"
              src={config.BASE_API_URL + '/public/img/asif/hero.avif'}
              sx={{ mx: 'auto', my: { xs: 2, sm: 1 } }}
            />
          </div>
        </Stack>

        <h2 style={{ paddingTop: '20px' }}>
          <b>আমাদের কোর্স সমূহ</b>
        </h2>
        <div
          style={{ height: '2px', backgroundColor: '#0c366d', marginBottom: '5%', width: '100%', maxWidth: '400px' }}
          className="line-width"
        ></div>
        <div style={{ maxWidth: '90%', marginLeft: '3%' }}>
          <Slider {...courseCarasoulSettings} style={{ marginBottom: '30px' }} className="your-carousel">
            {allCourses.map((course, i) => (
              <div className="carousel-item">
                <Card
                  sx={{
                    maxWidth: 320,
                    backgroundColor: colors[i % 4],
                    border: '2px solid',
                    borderColor: borderColors[i % 4],
                    marginLeft: '4%',
                  }}
                >
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="200"
                      image={config.BASE_API_URL + get(course, 'image')}
                      alt="course image"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div" sx={{ color: '#0c366d' }}>
                        {get(course, 'title')}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {get(course, 'description')}
                      </Typography>
                      <Typography variant="body1" color="text.primary" sx={{ marginTop: '5px' }}>
                        <b>Teachers: {get(course, 'teachers')}</b>
                      </Typography>
                      <Typography variant="body1" color="text.primary">
                        <b>
                          মেয়াদ:
                          {get(course, 'time')}{' '}
                        </b>
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'flex-end' }}>
                    <div>
                      <Typography variant="body1" color={borderColors[i % 4]}>
                        <b>
                          মাত্র:
                          {get(course, 'discountedPrice') != null ? (
                            <del> {get(course, 'price')} </del>
                          ) : (
                            get(course, 'price')
                          )}
                          {get(course, 'discountedPrice') != null && get(course, 'discountedPrice')}{' '}
                        </b>
                      </Typography>
                    </div>
                    <div>
                      <Button
                        size="small"
                        sx={{ backgroundColor: borderColors[i % 4] }}
                        variant="contained"
                        onClick={() => openCourseDetailsModal(course)}
                      >
                        Register
                      </Button>
                    </div>
                  </CardActions>
                </Card>
              </div>
            ))}
          </Slider>
        </div>

        <h2 style={{ paddingTop: '50px' }}>
          <b>আমাদের বই সমূহ</b>
        </h2>
        <div
          style={{ height: '2px', backgroundColor: '#0c366d', marginBottom: '5%', width: '100%', maxWidth: '400px' }}
          className="line-width"
        ></div>
        <div style={{ maxWidth: '90%', marginLeft: '3%' }}>
          <Slider {...courseCarasoulSettings} style={{ marginBottom: '30px' }} className="your-carousel">
            {BOOKS.map((course, i) => (
              <div className="carousel-item">
                <Card
                  sx={{
                    maxWidth: 320,
                    backgroundColor: colors[i % 4],
                    border: '2px solid',
                    borderColor: borderColors[i % 4],
                    marginLeft: '4%',
                  }}
                >
                  <CardActionArea>
                    <CardMedia component="img" height="400" image={get(course, 'image')} alt="course image" />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div" sx={{ color: '#0c366d' }}>
                        {get(course, 'title')}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {get(course, 'description')
                          .split('=>')
                          .map((line, index) => (
                            <React.Fragment key={index}>
                              {line}
                              <br />
                            </React.Fragment>
                          ))}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>
            ))}
          </Slider>
        </div>

        <h2 style={{ paddingTop: '20px' }}>
          <b>আমাদের অর্জন</b>
        </h2>
        <div
          style={{ height: '2px', backgroundColor: '#081f3e', marginBottom: '5%', width: '100%', maxWidth: '400px' }}
          className="line-width"
        ></div>
        <div style={{ maxWidth: '90%', marginLeft: '3%' }}>
          <Slider {...settings} style={{ marginBottom: '30px' }}>
            {achivements.map((image, i) => (
              <Box key={i} component="img" src={config.BASE_API_URL + image} sx={{ pl: 2, pr: 2 }} />
              // <img key={i} src={image} style={{marginLeft: '3%'}}></img>
            ))}
          </Slider>
        </div>

        <h2 style={{ paddingTop: '20px' }}>
          <b>রিভিউ</b>
        </h2>
        <div
          style={{ height: '2px', backgroundColor: '#0c366d', marginBottom: '5%', width: '100%', maxWidth: '400px' }}
          className="line-width"
        ></div>
        <div style={{ maxWidth: '90%', marginLeft: '3%' }}>
          <Slider {...courseCarasoulSettings} style={{ marginBottom: '50px' }} className="your-carousel">
            {reviews.map((review, i) => (
              <div className="carousel-item">
                <Card
                  sx={{ maxWidth: 320, border: '2px solid', borderColor: borderColors[(i + 3) % 4], marginLeft: '4%' }}
                >
                  <CardActionArea>
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div" sx={{ color: '#0c366d' }}>
                        <Icon icon="fa:quote-left" style={{ fontSize: '25px' }} />
                      </Typography>
                      <Typography variant="body2" color="text.primary" sx={{ fontSize: '13px' }}>
                        {get(review, 'review')}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      backgroundColor: colors[(i + 3) % 4],
                    }}
                  >
                    <div>
                      <img
                        src={config.BASE_API_URL + get(review, 'studentImage')}
                        style={{ height: '60px', width: '60px', borderRadius: '50%' }}
                      ></img>
                    </div>
                    <div>
                      <Typography variant="h6" component="div" color={borderColors[(i + 3) % 4]}>
                        <b>{get(review, 'studentName')}</b>
                      </Typography>
                      <Typography variant="body2" color="#0c366d">
                        <b>{get(review, 'descriptionAboutRank')}</b>
                      </Typography>
                    </div>
                  </CardActions>
                </Card>
              </div>
            ))}
          </Slider>
        </div>

        <h2 style={{ paddingTop: '20px' }}>
          <b>Contact Us</b>
        </h2>
        <div
          style={{ height: '2px', backgroundColor: '#081f3e', marginBottom: '5%', width: '100%', maxWidth: '400px' }}
          className="line-width"
        ></div>
        <div style={{ maxWidth: '90%', marginLeft: '3%' }}>
          <div
            style={{
              marginBottom: '30px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            {contactInfo.map((info, i) => (
              <div
                style={{
                  maxWidth: 300,
                  minWidth: 100,
                  border: '2px solid',
                  borderColor: get(info, 'color'),
                  marginLeft: '4%',
                  backgroundColor: get(info, 'backgroundColor'),
                  marginBottom: '4%',
                  borderRadius: '15px',
                }}
              >
                <Tooltip
                  title={get(info, 'info')}
                  // onClick={(e)=> handleCopy(get(info, 'info'))}
                >
                  {/* <Link to={get(info, 'Link')}> */}
                  <Card
                    onClick={(e) => {
                      handleCardClick(info);
                    }}
                  >
                    <CardActionArea>
                      <CardContent style={{ backgroundColor: get(info, 'backgroundColor') }}>
                        <Typography gutterBottom variant="h5" component="div" sx={{ color: get(info, 'color') }}>
                          {get(info, 'Icon')}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          <b>{get(info, 'media')}</b>
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                  {/* </Link> */}
                </Tooltip>
              </div>
              // <img key={i} src={image} style={{marginLeft: '3%'}}></img>
            ))}
          </div>
        </div>
      </Container>

      <PageFooter />

      {/* HISTORY MODAL */}
      <div>
        <Dialog open={courseRegModal} onClose={() => setCourseRegModal(false)}>
          <DialogTitle>Course Registration Form</DialogTitle>
          <CardMedia
            component="img"
            height="210"
            image={config.BASE_API_URL + get(courseRegData, 'courseImage')}
            alt={get(courseRegData, 'courseTitle', '')}
          />

          <DialogTitle>Course: {get(courseRegData, 'courseTitle', '')} </DialogTitle>

          {/* <DialogTitle>Please fill up your information</DialogTitle> */}

          <DialogContent>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                name="phone"
                label="Phone Number"
                style={{ width: '100%' }}
                value={get(courseRegData, 'phone', '')}
                onChange={(e) => {
                  handleCourseRegDataChange('phone', e);
                }}
                required
              />
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
              {' '}
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                name="firstname"
                label="First Name"
                style={{ width: '100%' }}
                value={get(courseRegData, 'firstname', '')}
                onChange={(e) => {
                  handleCourseRegDataChange('firstname', e);
                }}
                required
              />

              <TextField
                name="lastname"
                label="Last Name"
                style={{ width: '100%' }}
                value={get(courseRegData, 'lastname', '')}
                onChange={(e) => {
                  handleCourseRegDataChange('lastname', e);
                }}
                required
              />
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
              {' '}
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                name="address"
                label="School/College Name"
                style={{ width: '100%' }}
                value={get(courseRegData, 'address', '')}
                onChange={(e) => {
                  handleCourseRegDataChange('address', e);
                }}
                required
              />
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
              {' '}
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                name="guardianSMS"
                label="Guardian Phone"
                style={{ width: '100%' }}
                value={get(courseRegData, 'guardianSMS', '')}
                onChange={(e) => {
                  handleCourseRegDataChange('guardianSMS', e);
                }}
                required
              />

              <FormControl fullWidth required style={{ width: '100%' }}>
                <InputLabel id="sessiom-label">HSC Batch</InputLabel>
                <Select
                  id="session-label"
                  defaultValue={''}
                  label="session"
                  onChange={(e) => {
                    handleSessionFilter(e);
                  }}
                >
                  {sessions.map((option) => (
                    <MenuItem value={option} key={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
              {' '}
            </Stack>
            <Button variant="contained" style={{ color: 'white', backgroundColor: 'blue' }} onClick={() => regCourse()}>
              Free Register
            </Button>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      </div>
    </Page>
  );
}
