import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// APIS
import { getAllBatches } from 'src/axiosLib/services/batch/admin';

// material
import {
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextareaAutosize,
  Typography,
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { UserListHead } from '../sections/@dashboard/user';
// mock
// import USERLIST from '../_mock/user';

// ----------------------------------------------------------------------

const TABLE_HEAD_BATCH = [
  { id: 'name', label: 'Batch Name', alignRight: false },
  { id: 'address', label: 'Branch Address', alignRight: false },
  // { id: 'session', label: 'Session', alignRight: false },
  { id: 'phone', label: 'Contact phone', alignRight: false },
  
  { id: 'sendSms', label: 'Send SMS', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function BatchWisePaymentSummary() {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [smsBodyBatch, setSmsBodyBatch] = useState('');

  const [batchList, setBatchList] = useState([]);

  const [batchCount, setBatchCount] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [batchModal, setBatchModal] = useState(false);

  const [characterCount, setCharacterCount] = useState(0);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

 

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };


  const getBatches = (rowsPerPage, page) => {
    try {
      getAllBatches(rowsPerPage, page, (error, data) => {

        if (!error) {
          // console.log(data);
          setBatchList(get(data, 'batches', []));
          setBatchCount(get(data, 'size', 0));
        } else {
          navigate('/login', { replace: true });
        }
      }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleBatchDataChange = (fieldName, event) =>{
    // console.log(event.target.value);
    setCharacterCount(event.target.value.length);
    setSmsBodyBatch({...smsBodyBatch , [fieldName] : event.target.value});
  }

  const handlePaymentDataChange = (fieldName, event) =>{
    // console.log(event.target.value);
    setPaymentReqBody({...smsBodyBatch , [fieldName] : event.target.value});
  }

  const sortedBatch = applySortFilter(batchList, getComparator(order, orderBy), filterName);

  useEffect(() => {
    getBatches(rowsPerPage, page);

  }, [page, rowsPerPage]);

  return (
    <Page title="Revenue Track">
      <Container>
          <Typography variant="h4" gutterBottom>
            Batch wise Revenue Tracking Tool
          </Typography>
     
{/* Batch */}
        <Card>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}> </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD_BATCH}
                  rowCount={batchList.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {sortedBatch.map((row) => {
                    return (
                      <TableRow
                        hover
                        key={get(row, '_id', '')}
                        tabIndex={-1} >
                        <TableCell align="left"> {get(row, 'batchName', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'branchId.branchName', '')}</TableCell>
                        <TableCell align="left">  {get(row, 'branchId.mobile', '')}</TableCell>

                        <TableCell align="left"> {
                          <Button variant="contained" component={RouterLink}
                          to= {"/dashboard/batchWisePaymentDetails/?batchId=" + (get(row,'_id')) }>
                             VIEW ALL TRANSACTIONS
                         </Button>
                          }
                          </TableCell>
                      </TableRow>
                    );
                  })}

                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={batchCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={10}> </Stack>
      </Container>


      {/* BATCH MODAL*/}
      <div>
            <Dialog open={batchModal} onClose={() => setBatchModal(false)}>
              <DialogTitle>Write SMS</DialogTitle>
              <DialogContent>

              <TextareaAutosize name="message" 
                label="Batch Name" 
                style={{width : "100%", marginTop : 20, fontSize: '20px', fontFamily: 'Public Sans,sans-serif'}} 
                value={get(smsBodyBatch, 'body', '')}
                onChange={(e) => {handleBatchDataChange("body", e)}}
                minRows="5"
                required/>
              <p>{characterCount}</p>
              
              </DialogContent>

              <DialogActions>
                <Button variant="contained" 
                onClick={()=>{batchWiseSMS()}} 
                style={{ color: "white", backgroundColor: 'blue' }}>SEND SMS</Button>
              </DialogActions>
            </Dialog>
        </div>


    </Page>
  );
}
