import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import { get } from 'lodash';
import { loginUsername } from 'src/axiosLib/services/user';
// ----------------------------------------------------------------------

export default function UserLoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [studentInfo, setStudentInfo] = useState({});

  const LoginSchema = Yup.object().shape({
    email: Yup.string().ensure('Username/password not found').required('username/phone is required'),
    password: Yup.string().required('username/phone not found'),
  });

  const defaultValues = {
    email: '',
    password: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async () => {
    // navigate('/dashboard/app', { replace: true });
  };

  const handlelogin=(studentInfo)=>{

    const temp = {
      "phone" : get(studentInfo, 'phone').trim(),
      "password" : get(studentInfo, 'password').trim()
    }

    loginUsername(temp, (err,data)=>{  

      
      if(!err){
        localStorage.setItem('jwtToken', data.token);
        localStorage.setItem('type', "user");

        navigate('/user/currentExams', { replace: true });
      }else{
        alert(JSON.stringify(err))
      }
    })
  }

  const handleChange = (fieldName, event) =>{
    setStudentInfo({...studentInfo, [fieldName] : event.target.value});
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="phone" label="Phone Number" type="text" value={get(studentInfo, 'phone', '')}
        onChange={(e) =>{handleChange('phone', e)}}
        />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          onChange={(e) =>{handleChange('password', e)}}
          value={get(studentInfo, 'password', '')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack> */}
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}> </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained"
       loading={isSubmitting} onClick={(e)=> {handlelogin(studentInfo)}}>
        Login Student
      </LoadingButton>

    </FormProvider>

  );
}
