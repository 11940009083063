import { get, set } from 'lodash';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { getUsersFiltered, removeUser, setValidTill, usersExport } from 'src/axiosLib/services/user/admin';
// material
import {
  Avatar,
  Button,
  Card,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import { sessions } from 'src/util/helpers';
import moment from 'moment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getAllBatches } from 'src/axiosLib/services/batch/admin';
import { getAllBranches } from 'src/axiosLib/services/branch/admin';
import { Downloading } from '@mui/icons-material';
// import {XLSX} from "xlsx";
// mock
// import USERLIST from '../_mock/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'roll', label: 'Roll', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  // { id: 'session', label: 'Session', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'guardianSMS', label: 'Guardian SMS', alignRight: false },
  { id: 'accessControl', label: 'Access Control', alignRight: false },
  { id: 'valid_till', label: 'Validity', alignRight: false },
  { id: 'isValid', label: 'Is Valid', alignRight: false },
  { id: 'profile', label: 'View Profile', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function User() {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);

  const [sessionField, setSessionField] = useState([]);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [userList, setUserList] = useState([]);

  const [userCount, setUserCount] = useState(0);

  const [batchList, setBatchList] = useState([]);

  const [branchList, setBranchList] = useState([]);

  const [branchField, setBranchField] = useState('');

  const [batchField, setBatchField] = useState('');

  useEffect(() => {
    console.log(sessionField);
    getUsers(rowsPerPage, page);
  }, [rowsPerPage, page, batchField, branchField, sessionField]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = userList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const getBatches = () => {
    try {
      getAllBatches(1000, 0, (error, data) => {
        if (!error) {
          // console.log(data);
          setBatchList(get(data, 'batches', []));
        } else {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getBranches = () => {
    try {
      getAllBranches(1000, 0, (error, data) => {
        if (!error) {
          // console.log(data);
          setBranchList(get(data, 'branches', []));
        } else {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setRowsPerPage(10);
    setFilterName(event.target.value);
  };

  const onView = async (stdid) => {
    openInNewTab('/dashboard/register_student/?std=' + stdid);
    navigate('/dashboard/register_student/?std=' + stdid, { replace: true });
  };

  const getUsers = (rowsPerPage, page) => {
    try {
      getUsersFiltered(
        filterName,
        rowsPerPage,
        page,
        branchField,
        batchField,
        sessionField,

        (error, data) => {
          if (!error) {
            setUserList(get(data, 'user', []));
            setUserCount(get(data, 'total', 0));
          } else {
            console.log(error);
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleBatchFilter = (e) => {
    setBatchField(e.target.value);
    // getUsers(rowsPerPage , page, batchField, branchField);
  };

  const handleBranchFilter = (e) => {
    setBranchField(e.target.value);
  };

  const handleSessionFilter = (e) => {
    setSessionField(e.target.value);
  };

  const isUserNotFound = userList.length === 0;

  const downloadExcel = () => {
    const studentData = {
      batchId: batchField,
      branchId: branchField,
      text: filterName,
      session: sessionField,
      sortOrder: {
        createdAt: 0,
        name: 0,
        roll: 0,
        validTill: 0,
      },
    };

    usersExport(studentData, (error, data) => {
      if (!error) {
        var XLSX = require('xlsx');
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'Student List.xlsx');
      } else {
        console.log(error);
      }
    });
  };

  useEffect(() => {
    getBatches();
    getBranches();
  }, []);

  useEffect(() => {
    if (filterName !== null && filterName !== '') {
      getUsers(rowsPerPage, page, batchField, branchField);
    }
  }, [filterName, page, rowsPerPage, batchField, branchField]);

  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
          <Typography variant="h4" gutterBottom>
            Verified Student List
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/register_student"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add New Student
          </Button>
        </Stack>

        <Card>
          <Stack direction="row" alignItems="center" justifyContent="space-around" spacing={5}>
            <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} />
            <FormControl fullWidth required style={{ width: '30%' }}>
              <InputLabel id="sessiom-label">Session</InputLabel>
              <Select
                // labelId="session"
                id="session-label"
                defaultValue={''}
                // value={get(sessionField , '')}
                label="Session"
                onChange={(e) => {
                  handleSessionFilter(e);
                }}
              >
                {sessions.map((option) => (
                  <MenuItem value={option} key={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth required style={{ width: '50%' }}>
              <InputLabel id="sessiom-label">Branch Name</InputLabel>
              <Select
                id="branch-label"
                // value={batchField}
                defaultValue={''}
                label="Batch Name"
                onChange={(e) => {
                  handleBranchFilter(e);
                }}
              >
                {branchList.map((batch) => (
                  <MenuItem key={batch.branchName} value={batch._id}>
                    {batch.branchName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth required style={{ width: '50%' }}>
              <InputLabel id="sessiom-label">Batch Name</InputLabel>
              <Select
                id="session-label"
                // value={get(grantData, 'batchId', '')}
                defaultValue={''}
                label="Batch Name"
                onChange={(e) => handleBatchFilter(e)}
              >
                {batchList.map((batch) => (
                  <MenuItem key={batch.batchName} value={batch._id}>
                    {batch.batchName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              variant="contained"
              onClick={(e) => {
                downloadExcel();
              }}
              style={{ color: 'white', backgroundColor: 'blue', width: '20%', marginRight: 15 }}
            >
              <Downloading style={{ color: 'white', marginRight: 5 }} />
              DOWNLOAD
            </Button>
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={userList.length}
                  // numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  // onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {/* {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => { */}
                  {/* // const { id, name, role, status, company, avatarUrl, isVerified } = row;
                    // const isItemSelected = selected.indexOf(name) !== -1; */}
                  {userList.map((row, i) => {
                    return (
                      <TableRow hover key={get(row, '_id', '')} tabIndex={-1}>
                        <TableCell align="left"> {get(row, 'roll', '')}</TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={get(row, 'username', '')} src={get(row, 'avatar', '')} />
                            <Typography variant="subtitle2" noWrap>
                              {get(row, 'username', '')}
                            </Typography>
                          </Stack>
                        </TableCell>
                        {/* <TableCell align="left"> {get(row,'session' , '')}</TableCell> */}
                        <TableCell align="left"> {get(row, 'phone', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'guardianSMS', '')}</TableCell>
                        <TableCell align="left">
                          {' '}
                          {
                            <Button
                              variant="contained"
                              onClick={(e) => {
                                removeUser(get(row, '_id'), (err, data) => {
                                  if (!err) {
                                    let demoUsers = userList.filter((user) => {
                                      return user._id !== get(row, '_id');
                                    });
                                    setUserList(demoUsers);
                                  }
                                });
                              }}
                              style={{ color: '#d53624', backgroundColor: 'white' }}
                            >
                              <BlockIcon style={{ color: '#d53624', marginRight: 5 }} />
                              Block
                            </Button>
                          }
                        </TableCell>
                        <TableCell align="left">
                          {' '}
                          {
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                label="account Valid Till"
                                name="validTill"
                                value={moment(get(row, 'validTill', null)).format('yyyy-MM-DD')}
                                onChange={(newValue) => {
                                  const date = new Date(newValue);
                                  setValidTill(get(row, '_id'), date, (err, data) => {
                                    if (!err) {
                                      let demoUsers = [...userList];
                                      set(demoUsers[i], 'validTill', date);
                                      setUserList(demoUsers);
                                    }
                                  });
                                }}
                                inputFormat="DD/MM/YYYY"
                                renderInput={(params) => <TextField {...params} />}
                                // style={{width : "50%"}}
                                sx={{ width: 220 }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </LocalizationProvider>
                          }
                        </TableCell>

                        <TableCell align="left">
                          {get(row, 'isVerified') == false ? (
                            <Button
                              variant="contained"
                              style={{
                                background: 'red',
                                color: '#fff',
                              }}
                            >
                              INVALID
                            </Button>
                          ) : new Date(get(row, 'validTill')) < new Date() ? (
                            <Button
                              variant="contained"
                              style={{
                                background: 'red',
                                color: '#fff',
                              }}
                            >
                              EXPIRED
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              style={{
                                background: 'green',
                                color: '#fff',
                              }}
                            >
                              VALID
                            </Button>
                          )}
                        </TableCell>

                        <TableCell align="left">
                          {' '}
                          {
                            <Button
                              variant="contained"
                              component={RouterLink}
                              to={'/dashboard/register_student/?std=' + get(row, '_id')}
                            >
                              VIEW
                            </Button>
                          }
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={userCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
