import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// APIS
import { getAllBatches } from 'src/axiosLib/services/batch/admin';
// material
import {
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { UserListHead } from '../sections/@dashboard/user';
import moment from 'moment';
import { Download, Edit } from '@mui/icons-material';
// mock
// import USERLIST from '../_mock/user';

// ----------------------------------------------------------------------

const TABLE_HEAD_BATCH = [
  { id: 'name', label: 'Branch Name', alignRight: false },
  { id: 'address', label: 'Branch Address', alignRight: false },
  // { id: 'session', label: 'Session', alignRight: false },
  { id: 'email', label: 'Contact Email', alignRight: false },
  // { id: 'email', label: 'Phone', alignRight: false },
  { id: 'updatedAt', label: 'Valid Till', alignRight: false },
  // { id: 'registered_at', label: 'Registered at', alignRight: false },
  { id: 'update', label: 'Update All', alignRight: false },
  { id: 'download', label: 'Download Details', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function User() {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [batchList, setBatchList] = useState([]);

  const [batchCount, setBatchCount] = useState(0);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };


  const getBatches = (rowsPerPage, page) => {
    try {
      getAllBatches(rowsPerPage, page, (error, data) => {

        if (!error) {
          // console.log(data);
          setBatchList(get(data, 'batches', []));
          setBatchCount(get(data, 'size', 0));
        } else {
          navigate('/login', { replace: true });
        }
      }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const sortedBatch = applySortFilter(batchList, getComparator(order, orderBy), filterName);

  useEffect(() => {
    getBatches(rowsPerPage, page);

  }, [filterName, page, rowsPerPage]);

  return (
    <Page title="Institute Details">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h3" gutterBottom>
            Batch Details and Info Download
          </Typography>
        </Stack>


{/* Batch */}
        <Card>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
            <Typography variant="h4" gutterBottom style={{ marginLeft: 15 , color: "#FF0000", backgroundColor: 'white' }} >
              Batch Information
            </Typography>
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD_BATCH}
                  rowCount={batchList.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {sortedBatch.map((row) => {
                    return (
                      <TableRow
                        hover
                        key={get(row, '_id', '')}
                        tabIndex={-1} >
                        <TableCell align="left"> {get(row, 'batchName', '')}</TableCell>
                        <TableCell align="left"> {row.branchId.branchName}</TableCell>
                        <TableCell align="left"> {row.branchId.mobile}</TableCell>


                        <TableCell align="left"> {
                          <TextField
                            id="date"
                            label="All Student New Validity"
                            type="date"
                            defaultValue={moment(row.updatedAt).format("yyyy-MM-DD")}
                            onChange={() => { console.log('changing date') }}
                            sx={{ width: 220 }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        }
                        </TableCell>
                        <TableCell align="left"> {
                          <Button variant="contained"
                            // onClick={() => this.props.onBlock(i, user._id)}
                            style={{ color: "#FFFFFF", backgroundColor: 'red' }} >
                            <Edit style={{ color: "#FFFFFF", marginRight: 5 }} /> UPDATE ALL
                          </Button>
                        }</TableCell>

                        <TableCell align="left"> {
                          <Button variant="contained"
                            // onClick={() => this.props.onBlock(i, user._id)}
                            style={{ color: "#FFFFFF", backgroundColor: 'blue' }} >
                            <Download style={{ color: "#FFFFFF", marginRight: 5 }} /> DOWNLOAD
                          </Button>
                        }</TableCell>
                      </TableRow>
                    );
                  })}

                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={batchCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={10}> </Stack>
      </Container>
    </Page>
  );
}
