import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {
  Alert,
  Button,
  Card,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
// components
import { FormProvider } from '../../../components/hook-form';

import moment from 'moment';
import { get } from 'lodash';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Scrollbar from 'src/components/Scrollbar';
import { UserListHead } from 'src/sections/@dashboard/user';
import { Downloading } from '@mui/icons-material';
import { getProfile } from 'src/axiosLib/services/user';
import { getMyExamPerformance, getResultSheet } from 'src/axiosLib/services/resultSheet';

// ----------------------------------------------------------------------

export default function UserResultExcel() {
  const navigate = useNavigate();

  const [studentInfo, setStudentInfo] = useState({ validTill: moment().format('DD/MM/YYYY'), session: 2024 });

  const [alertContent, setAlertContent] = useState('');
  const [studentId, setStudentId] = useState(null);

  const [examList, setExamList] = useState([]);

  const TABLE_HEAD = [
    { id: 'name', label: 'Exam Name', alignRight: false },
    { id: 'maxMarks', label: 'Highest Marks', alignRight: false },
    // { id: 'session', label: 'Session', alignRight: false },
    { id: 'mymarks', label: 'My Marks', alignRight: false },
    // { id: 'present', label: 'Status', alignRight: false },
    { id: 'totalMarks', label: 'Total Marks', alignRight: false },
    { id: 'correct', label: 'Correct', alignRight: false },
    { id: 'incorrect', label: 'Incorrect', alignRight: false },
    // { id: 'registered_at', label: 'Registered at', alignRight: false },
    { id: 'skipped', label: 'Skipped', alignRight: false },
    { id: 'date', label: 'Date', alignRight: false },
  ];

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required('First name required'),
    lastName: Yup.string().required('Last name required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async () => {
    navigate('/dashboard/user', { replace: true });
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const stdId = urlParams.get('std');

    getProfile((err, data) => {
      if (!err) {
        setStudentInfo(data);
        setStudentId(data._id);
      } else {
        navigate('/login', { replace: true });
        // console.log("logging out")
      }
    });

    getMyExamPerformance({ limit: 100, page: 0 }, (err, data) => {
      if (!err) {
        // console.log(data);

        setExamList(get(data, 'exams', []));
      }
    });
  }, []);

  const downloadExcel = () => {
    getResultSheet({ limit: 5000, page: 0 }, (error, data) => {
      if (!error) {
        var XLSX = require('xlsx');
        const worksheet = XLSX.utils.json_to_sheet(get(data, 'exams', []));
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'My Exam Performance.xlsx');
      } else {
        console.log(error);
      }
    });
  };

  const renderExcel = () => {
    return (
      <div>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={15}>
          {' '}
        </Stack>

        {/* Batch */}
        <Card>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
            <Typography
              variant="h4"
              gutterBottom
              style={{ marginLeft: 15, color: '#FF0000', backgroundColor: 'white' }}
            >
              Exam Performance
            </Typography>

            <Button
              variant="contained"
              onClick={(e) => {
                downloadExcel();
              }}
              style={{ color: 'white', backgroundColor: 'blue', width: '50%', marginRight: 15 }}
            >
              <Downloading style={{ color: 'white', marginRight: 5 }} />
              DOWNLOAD
            </Button>
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  // order={order}
                  // orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={examList.length}
                />
                <TableBody>
                  {examList.map((row, index) => {
                    return (
                      <TableRow hover key={index} tabIndex={-1}>
                        <TableCell align="left"> {get(row, 'examName', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'maxMarks', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'myMarks', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'totalMarks', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'correct', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'incorrect', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'skipped', '')}</TableCell>
                        <TableCell align="left"> {moment(get(row, 'date', '')).format('yyyy-MM-DD hh:mm A')}</TableCell>
                        <TableCell align="left">
                          {get(row, '_id', '') ? (
                            <Button
                              variant="contained"
                              component={RouterLink}
                              to={'/user/checkAnswer/?examId=' + get(row, '_id')}
                            >
                              VIEW
                            </Button>
                          ) : (
                            console.log('VIEW')
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={10}>
          {' '}
        </Stack>
      </div>
    );
  };

  return (
    <div>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              name="firstName"
              label="First name"
              value={get(studentInfo, 'firstname', '')}
              style={{ width: '100%' }}
            />
            <TextField
              name="lastName"
              label="Last name"
              value={get(studentInfo, 'lastname', '')}
              style={{ width: '100%' }}
            />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              name="email"
              label="Email address"
              value={get(studentInfo, 'email', '')}
              style={{ width: '100%' }}
            />
            <TextField
              name="username"
              label="User Name"
              value={get(studentInfo, 'username', '')}
              style={{ width: '100%' }}
            />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              name="phone"
              label="Student Phone"
              value={get(studentInfo, 'phone', '')}
              style={{ width: '100%' }}
            />
            <TextField
              name="guardianSms"
              label="Guardian SMS"
              value={get(studentInfo, 'guardianSMS', '')}
              style={{ width: '100%' }}
            />
          </Stack>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Valid Till"
              name="validTill"
              value={moment(get(studentInfo, 'validTill')).format('yyyy-MM-DD')}
              inputFormat="DD/MM/YYYY"
              onChange={(newValue) => {}}
              renderInput={(params) => <TextField {...params} />}
              style={{ width: '50%' }}
            />
          </LocalizationProvider>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              name="address"
              label="College & District"
              value={get(studentInfo, 'address', '')}
              style={{ width: '100%' }}
            />
            <TextField
              name="roll"
              label="Unique Roll Number"
              value={get(studentInfo, 'roll', '')}
              style={{ width: '100%' }}
            />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            {/* <TextField name="session" label="Session" style={{width : "100%"}} required/> */}

            <TextField
              name="Session"
              label="Session"
              value={get(studentInfo, 'session', '')}
              style={{ width: '100%' }}
            />

            <TextField
              name="branch"
              label="Branch"
              value={
                studentInfo.branchId
                  ? get(studentInfo, 'branchId', '')
                      .map((o) => o.branchName)
                      .join('; ')
                  : ' '
              }
              style={{ width: '100%' }}
            />

            <TextField
              name="batch"
              label="Batch"
              value={
                studentInfo.batchId
                  ? get(studentInfo, 'batchId', '')
                      .map((o) => o.batchName)
                      .join('; ')
                  : ''
              }
              style={{ width: '100%' }}
            />

            {/* <TextField name="batch" label="Batch" defaultValue="test" style={{width : "100%"}} required/> */}
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              name="fathersName"
              label="Father's Name"
              value={get(studentInfo, 'fathersName', '')}
              style={{ width: '100%' }}
            />
            <TextField
              name="fathersPhone"
              label="Father's Phone"
              value={get(studentInfo, 'fathersMobileNumber', '')}
              style={{ width: '100%' }}
            />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              name="mothersName"
              label="Mother's Name"
              value={get(studentInfo, 'mothersName', '')}
              style={{ width: '100%' }}
            />
            <TextField
              name="mothersPhone"
              label="Mother's Phone"
              value={get(studentInfo, 'mothersMobileNumber', '')}
              style={{ width: '100%' }}
            />
          </Stack>
        </Stack>

        {alertContent !== '' ? (
          <Alert variant="filled" severity="error">
            {alertContent}
          </Alert>
        ) : (
          <div />
        )}
      </FormProvider>

      {studentId && renderExcel()}
    </div>
  );
}
