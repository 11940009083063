import { getReqAuth, postReqAuth } from '../../api';

// 1. getall users
// setting up mock data
export const getAllUsers = (limit, page, cb) =>
	postReqAuth("/webapi/institute/user/get/filtered", {limit, page}, "", cb);

export const getSMSPercentage = (cb) =>
	getReqAuth("/webapi/institute/institution/get/sms/percentage", {}, "", cb);

export const getInstitutionDetail = (cb) =>
	getReqAuth("/webapi/institute/institution/get/detail", {}, "", cb);

export const updateInstitutionInfo = (data, cb) =>
	postReqAuth("/webapi/institute/institution/update", data, "", cb);

export const getCpu = (cb) =>
	getReqAuth("/webapi/institute/institution/serverStat", {}, "", cb);

export const adminLevel = (cb) =>
	getReqAuth("/webapi/institute/auth/admin-level", {}, "", cb);

export const getAllInstitutions = (cb) =>
	getReqAuth("/webapi/institute/institution/get/all", {}, "", cb);

export const changeAdminInstitution = (data, cb) =>
	postReqAuth("/webapi/institute/institution/changeAdminInstitution", data, "", cb);
