// @mui
import { Container, Typography } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
// sections
import UserResultExcel from 'src/sections/auth/register/UserResultExcel';

// ----------------------------------------------------------------------


// ----------------------------------------------------------------------

export default function UserResult() {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Register Student">
      {/* <RootStyle> */}

        {/* {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Manage the job more effectively with Minimal
            </Typography>
            <img alt="register" src="/static/illustrations/illustration_register.png" />
          </SectionStyle>
        )} */}

        <Container>
          {/* <ContentStyle> */}
            <Typography variant="h4" gutterBottom>
              My Profile
            </Typography>

            {/* <Typography sx={{ color: 'text.secondary', mb: 5 }}>Please fill up the details</Typography> */}

            <UserResultExcel />

          {/* </ContentStyle> */}
        </Container>
      {/* </RootStyle> */}
    </Page>
  );
}
