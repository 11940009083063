import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// APIS

// material
import {
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
// components
import Page from '../components/Page';
import { MuiFileInput } from 'mui-file-input';
import { sendDynamicSMS, uploadExcelForSMS } from 'src/axiosLib/services/sms/admin';
// mock
// import USERLIST from '../_mock/user';


export default function DynamicSmsSystem() {
  const navigate = useNavigate();

  const [smsHeaders, setSmsHeaders] = useState([]);

  const [smsBody, setSmsBody] = useState('');

  const [message, setMessage] = useState('');

  const [file, setFile] = useState(null);

  const [fileName, setFileName] = useState('');

  const [phoneColumn, setPhoneColumn] = useState('');

  const [custom, setCustom] = useState('');

  const handleChangeExcel = (event) => {
    setFile(event);
  };


  const handleChange = (fieldName, event) =>{
    if(fieldName == "custom"){
      setMessage(message + '<%' + event.target.value + '%>');
    }
  }

  const UploadFile = () =>{
    const formData = new FormData();
		formData.append('file', file);

    uploadExcelForSMS( formData , (error, data) => {

      if (!error) {
       setSmsHeaders(data.headers);
       setFileName(data.fileName)

       alert('Successfully Uploaded File');
      }else{
        alert(error)
      }
    });
  }

  const sendSMS = () =>{
    // message
    // phone
    // fileName

    let temp = {
      'message' : message,
      'phone' : phoneColumn,
      'fileName' : fileName
    }

    sendDynamicSMS( temp , (error, data) => {

      if (!error) {
       alert(JSON.stringify(data));
       console.log(data)
      }else{
        alert(error)
      }
    });
  }

  const handleBatchDataChange = (fieldName, event) =>{
    // console.log(event.target.value);
    // setCharacterCount(event.target.value.length);
    // setSmsBodyBatch({...smsBodyBatch , [fieldName] : event.target.value});
  }

  const handleMessageDataChange = (fieldName, event) =>{
    // console.log(event.target.value);
    // setCharacterCount(event.target.value.length);
    setSmsBody({...smsBody , [fieldName] : event.target.value});
  }

  useEffect(() => {
    console.log("GHere");

  }, [message]);

  return (
    <Page title="SMS Service">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h3" gutterBottom>
            Dynamic SMS System
          </Typography>
        </Stack>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <MuiFileInput 
            value={file} 
            onChange={(e) => {handleChangeExcel(e)}}
            name="file" 
            placeholder="Please select an excel file" style={{width : "75%"}}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
          </MuiFileInput>

          <Button variant="contained" 
                  onClick={()=>{UploadFile()}} 
                  style={{ color: "white", width : "25%"}}>UPLOAD FILE
          </Button>
          
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}> </Stack>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <FormControl fullWidth required style={{width : "50%"}} >
            <InputLabel id="sessiom-label">Phone Number Column</InputLabel>
            <Select
              id="phone-label"
              value={phoneColumn}
              label="Phone Number Column"
              onChange={(e) => {setPhoneColumn(e.target.value)}}>
                {smsHeaders.map((option) => (
                  <MenuItem value={option} key={option}>
                    {option}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}> </Stack>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>

          <TextField 
            name="message" 
            label="Dynamic SMS" 
            value={message} 
            style={{width : "100%"}} 
            onChange={(e) =>{setMessage(e.target.value)}} 
            required
            multiline>
          </TextField>


          <FormControl fullWidth required style={{width : "50%"}} >
            <InputLabel id="sessiom-label">Customization</InputLabel>
            <Select
              id="phone-label"
              value={custom}
              label="Phone Number Column"
              onChange={(e) => {handleChange("custom", e)}}>
                {smsHeaders.map((option) => (
                  <MenuItem value={option} key={option}>
                    {option}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}> </Stack>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <Button variant="contained" 
                    onClick={()=>{sendSMS()}} 
                    style={{ color: "white", width : "100%"}}>SEND DYNAMIC SMS
          </Button>
        
        </Stack>
      </Container>
    </Page>
  );
}
