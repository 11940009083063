import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { usersExport } from 'src/axiosLib/services/user/admin';
// material
import {
  Avatar,
  Button,
  Card,
  CardMedia,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import moment from 'moment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Downloading } from '@mui/icons-material';
import { followupEnrollment, getAllEnrollments } from 'src/axiosLib/services/courseEnrollment/admin';
import config from 'src/util/config';
// import {XLSX} from "xlsx";
// mock
// import USERLIST from '../_mock/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'roll', label: 'Roll', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  // { id: 'session', label: 'Session', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'guardianSMS', label: 'Guardian SMS', alignRight: false },
  { id: 'accessControl', label: 'Access Control', alignRight: false },
  { id: 'valid_till', label: 'Validity', alignRight: false },
  { id: 'isValid', label: 'Is Valid', alignRight: false },
  { id: 'profile', label: 'View Profile', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function CourseEnrollment() {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);

  const [sessionField, setSessionField] = useState([]);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [userList, setUserList] = useState([]);

  const [userCount, setUserCount] = useState(0);

  const [statusField, setStatusField] = useState('New');

  const [followupSearchField, setFollowupSearchField] = useState({
    fromDate: moment().format('yyyy-MM-DD'),
    toDate: moment().format('yyyy-MM-DD'),
  });

  const currentState = [
    'Already Paid',
    'Will Pay',
    'Already Admitted',
    'Will Take Course',
    'Will Not Take Course',
    'Number Off',
    'Others',
  ];

  const nextStep = ['Call Again', 'Check Payment', 'Admit to Course', 'Decline Registration', 'Others'];

  const applicationStatus = ['New', 'Admitted', 'Declined'];

  const TABLE_HISTORY = [
    { id: 'followupBy', label: 'Follow Up By', alignRight: false },
    { id: 'comment', label: 'Comment', alignRight: false },
    { id: 'Current State', label: 'Current STate', alignRight: false },
    { id: 'nextStep', label: 'Next Step', alignRight: false },
    { id: 'Followup Date', label: 'Followup Date', alignRight: false },
  ];

  const [paymentHistoryModal, setPaymentHistoryModal] = useState(false);

  const [followupHistory, setFollowupHistory] = useState([{}]);

  const handlePaymentHistory = (row) => {
    setFollowupHistory(row);

    setPaymentHistoryModal(true);
  };

  const handleFollowupDataChange = (fieldName, event) => {
    // console.log(event.target.value);
    setFollowupHistory({ ...followupHistory, [fieldName]: event.target.value });
  };

  const getEnrollments = () => {
    let temp = {
      limit: rowsPerPage,
      page: page,
      status: statusField,
      fromDate: followupSearchField.fromDate,
      toDate: followupSearchField.toDate,
      text: filterName,
    };
    try {
      getAllEnrollments(temp, (error, data) => {
        if (!error) {
          console.log(data);
          // setBranchList(get(data, 'branches', []));

          setUserList(get(data, 'enrollments', []));
          setUserCount(get(data, 'size', 0));
        } else {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setRowsPerPage(10);
    setFilterName(event.target.value);
  };

  const removePaymentFromHistory = () => {
    console.log(followupHistory);

    followupEnrollment(
      followupHistory,

      (error, data) => {
        if (!error) {
          alert('Successfully Added Followup');

          setPaymentHistoryModal(false);

          // window.location.reload();
        } else {
          console.log(error);
        }
      }
    );
  };

  const handleArrayInputs = (e, fieldName) => {
    setFollowupHistory({ ...followupHistory, [fieldName]: e.target.value });
  };

  const isUserNotFound = userList.length === 0;

  const downloadExcel = () => {
    const studentData = {
      batchId: 'batchField',
      branchId: 'branchField',
      text: filterName,
      session: sessionField,
      sortOrder: {
        createdAt: 0,
        name: 0,
        roll: 0,
        validTill: 0,
      },
    };

    usersExport(studentData, (error, data) => {
      if (!error) {
        var XLSX = require('xlsx');
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'Student List.xlsx');
      } else {
        console.log(error);
      }
    });
  };

  // useEffect(() => {
  //   getEnrollments();
  // }, []);

  useEffect(() => {
    getEnrollments();
  }, [filterName, page, rowsPerPage, followupSearchField, statusField, filterName]);

  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
          <Typography variant="h4" gutterBottom>
            Course Registration List
          </Typography>
        </Stack>

        <Card>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            {' '}
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-around" spacing={5}>
            <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} />

            <FormControl fullWidth required style={{ width: '20%' }}>
              <InputLabel id="sessiom-label">Application Status</InputLabel>
              <Select
                id="session-label"
                defaultValue={statusField}
                label="Status"
                onChange={(e) => {
                  setStatusField(e.target.value);
                }}
              >
                {applicationStatus.map((option) => (
                  <MenuItem value={option} key={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Registration From Date"
                name="fromDate"
                value={moment(get(followupSearchField, 'fromDate')).format('yyyy-MM-DD')}
                onChange={(newValue) => {
                  setFollowupSearchField({ ...followupSearchField, fromDate: new Date(newValue) });
                  // setexamInfo({...examInfo, startTime: new Date(newValue)});
                }}
                inputFormat="DD/MM/YYYY"
                renderInput={(params) => <TextField {...params} />}
                sx={{ width: 220 }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Registration to Date"
                name="toDate"
                value={moment(get(followupSearchField, 'toDate')).format('yyyy-MM-DD')}
                onChange={(newValue) => {
                  setFollowupSearchField({ ...followupSearchField, toDate: new Date(newValue) });
                }}
                inputFormat="DD/MM/YYYY"
                renderInput={(params) => <TextField {...params} />}
                sx={{ width: 220 }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </LocalizationProvider>

            <Button
              variant="contained"
              onClick={(e) => {
                downloadExcel();
              }}
              style={{ color: 'white', backgroundColor: 'blue', width: '20%', marginRight: 15 }}
            >
              <Downloading style={{ color: 'white', marginRight: 5 }} />
              DOWNLOAD
            </Button>
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead order={order} orderBy={orderBy} headLabel={TABLE_HEAD} rowCount={userList.length} />
                <TableBody>
                  {/* {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => { */}
                  {/* // const { id, name, role, status, company, avatarUrl, isVerified } = row;
                    // const isItemSelected = selected.indexOf(name) !== -1; */}
                  {userList.map((row, i) => {
                    return (
                      <TableRow hover key={get(row, '_id', '')} tabIndex={-1}>
                        <TableCell align="left"> {get(row, 'courseId.title', '')}</TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={get(row, 'username', '')} src={get(row, 'avatar', '')} />
                            <Typography variant="subtitle2" noWrap>
                              {get(row, 'username', '')}
                            </Typography>
                          </Stack>
                        </TableCell>
                        {/* <TableCell align="left"> {get(row,'session' , '')}</TableCell> */}
                        <TableCell align="left"> {get(row, 'phone', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'guardianSMS', '')}</TableCell>
                        <TableCell align="left">
                          {' '}
                          {
                            <Button variant="contained" style={{ color: '#d53624', backgroundColor: 'white' }}>
                              {get(row, 'studentStatus', '')}
                            </Button>
                          }
                        </TableCell>
                        <TableCell align="left">
                          {' '}
                          {
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                label="Registration Date"
                                name="validTill"
                                value={moment(get(row, 'createdAt', null)).format('yyyy-MM-DD')}
                                inputFormat="DD/MM/YYYY"
                                renderInput={(params) => <TextField {...params} />}
                                // style={{width : "50%"}}
                                sx={{ width: 220 }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </LocalizationProvider>
                          }
                        </TableCell>

                        <TableCell align="left">
                          <Button
                            variant="contained"
                            style={{
                              background: 'green',
                              color: '#fff',
                            }}
                            onClick={() => handlePaymentHistory(row)}
                          >
                            Follow Up
                          </Button>
                        </TableCell>

                        <TableCell align="left">
                          {' '}
                          {get(row, 'userId', '') ? (
                            <Button
                              variant="contained"
                              component={RouterLink}
                              to={'/dashboard/register_student/?std=' + get(row, 'userId._id')}
                            >
                              VIEW
                            </Button>
                          ) : (
                            console.log('VIEW')
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={userCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      {/* HISTORY MODAL */}
      <div>
        <Dialog open={paymentHistoryModal} onClose={() => setPaymentHistoryModal(false)}>
          <DialogTitle>Followup History Tracking</DialogTitle>

          <DialogContent>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
              {' '}
            </Stack>
            <CardMedia
              component="img"
              height="250"
              image={config.BASE_API_URL + get(followupHistory, 'courseId.image')}
              alt={get(followupHistory, 'courseId.title', '')}
            />
            <DialogTitle>Course Name: {get(followupHistory, 'courseId.title', '')}</DialogTitle>

            <DialogTitle style={{ width: '100%', color: 'red' }}>Student Information Section</DialogTitle>

            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
              {' '}
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                name="phone"
                label="Phone Number"
                style={{ width: '100%' }}
                value={get(followupHistory, 'phone', '')}
                onChange={(e) => {
                  handleFollowupDataChange('phone', e);
                }}
                required
              />
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
              {' '}
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                name="firstname"
                label="First Name"
                style={{ width: '100%' }}
                value={get(followupHistory, 'firstname', '')}
                onChange={(e) => {
                  handleFollowupDataChange('firstname', e);
                }}
                required
              />

              <TextField
                name="lastname"
                label="Last Name"
                style={{ width: '100%' }}
                value={get(followupHistory, 'lastname', '')}
                onChange={(e) => {
                  handleFollowupDataChange('lastname', e);
                }}
                required
              />
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
              {' '}
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                name="guardianSMS"
                label="Guardian Phone"
                style={{ width: '100%' }}
                value={get(followupHistory, 'guardianSMS', '')}
                onChange={(e) => {
                  handleFollowupDataChange('guardianSMS', e);
                }}
                required
              />

              <TextField
                name="session"
                label="Session / HSC Year"
                style={{ width: '100%' }}
                value={get(followupHistory, 'session', '')}
                onChange={(e) => {
                  handleFollowupDataChange('session', e);
                }}
                required
              />
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
              {' '}
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                name="address"
                label="School / College"
                style={{ width: '100%' }}
                value={get(followupHistory, 'address', '')}
                onChange={(e) => {
                  handleFollowupDataChange('address', e);
                }}
                required
              />
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
              {' '}
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                name="studentStatus"
                label="Student Type"
                style={{ width: '100%' }}
                value={get(followupHistory, 'studentStatus', '')}
                onChange={(e) => {
                  handleFollowupDataChange('studentStatus', e);
                }}
                disabled
              />
            </Stack>

            <DialogTitle style={{ width: '100%', color: 'red' }}>Follow Up Section</DialogTitle>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                name="comment"
                label="Comment"
                style={{ width: '100%' }}
                value={get(followupHistory, 'comment', '')}
                onChange={(e) => {
                  handleFollowupDataChange('comment', e);
                }}
                required
              />
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
              {' '}
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <FormControl fullWidth required style={{ width: '100%' }}>
                <InputLabel id="sessiom-label">Current State</InputLabel>
                <Select
                  id="session-label"
                  defaultValue={''}
                  label="Current State"
                  onChange={(e) => {
                    handleArrayInputs(e, 'currentState');
                  }}
                >
                  {currentState.map((option) => (
                    <MenuItem value={option} key={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth required style={{ width: '100%' }}>
                <InputLabel id="sessiom-label">Next Step</InputLabel>
                <Select
                  id="session-label"
                  defaultValue={''}
                  label="Next Step"
                  onChange={(e) => {
                    handleArrayInputs(e, 'nextStep');
                  }}
                >
                  {nextStep.map((option) => (
                    <MenuItem value={option} key={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth required style={{ width: '100%' }}>
                <InputLabel id="sessiom-label">Application Status</InputLabel>
                <Select
                  id="session-label"
                  defaultValue={get(followupHistory, 'status', '')}
                  label="Status"
                  onChange={(e) => {
                    handleArrayInputs(e, 'applicationStatus');
                  }}
                >
                  {applicationStatus.map((option) => (
                    <MenuItem value={option} key={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
              {' '}
            </Stack>

            <TableContainer style={{ width: '100%', marginTop: 20 }}>
              <Table>
                <UserListHead headLabel={TABLE_HISTORY} rowCount={get(followupHistory, 'data', [{}]).length} />
                <TableBody>
                  {get(followupHistory, 'followup', [{}]).map((row) => {
                    return (
                      <TableRow hover key={get(row, '_id', '')} tabIndex={-1}>
                        <TableCell align="left"> {get(row, 'adminId.email', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'comment', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'currentState', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'nextStep', '')}</TableCell>
                        <TableCell align="left"> {moment(get(row, 'followupDate')).format('yyyy-MM-DD')}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={removePaymentFromHistory}
              style={{ color: 'white', backgroundColor: 'blue' }}
            >
              Add Follow Up History
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Page>
  );
}
