import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

// APIS
import { getAllExams } from 'src/axiosLib/services/exam/admin';

// material
import {
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import { UserListHead } from '../sections/@dashboard/user';
import moment from 'moment';
// mock
// import USERLIST from '../_mock/user';

// ----------------------------------------------------------------------

const TABLE_HEAD_EXAM = [
  { id: 'name', label: 'Exam Name', alignRight: false },
  { id: 'code', label: 'Exam Code', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'enrolled', label: 'Participants', alignRight: false },

  { id: 'marks', label: 'Marks', alignRight: false },
  { id: 'Start Time', label: 'Start Time', alignRight: false },
  { id: 'End Time', label: 'End Time', alignRight: false },
  // { id: 'registered_at', label: 'Registered at', alignRight: false },
  { id: 'profile', label: 'EDIT', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function OnlineMobileScannableExamList() {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [batchList, setBatchList] = useState([]);

  const [batchCount, setBatchCount] = useState(0);

  const [serverDate, setServerDate] = useState(new Date());

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // console.log(event.target.value)
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const getExams = (rowsPerPage, page) => {
    try {
      getAllExams(rowsPerPage, page, true, (error, data) => {
        if (!error) {
          // console.log(data);
          setBatchList(get(data, 'exams', []));
          setBatchCount(get(data, 'size', 0));
          setServerDate(new Date(get(data, 'serverTime', new Date())));
        } else {
          navigate('/login', { replace: true });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const sortedExam = applySortFilter(batchList, getComparator(order, orderBy), filterName);

  useEffect(() => {
    getExams(rowsPerPage, page);
  }, [filterName, page, rowsPerPage]);

  return (
    <Page title="Institute Details">
      <Container>
        {/* Exam */}
        <Card>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
            <Typography
              variant="h4"
              gutterBottom
              style={{ marginLeft: 15, color: '#FF0000', backgroundColor: 'white' }}
            >
              Mobile Scannable OMR Sheet Exam List
            </Typography>
            <Button
              style={{ marginRight: 10 }}
              variant="contained"
              component={RouterLink}
              to="/dashboard/examDetails"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Create New Exam
            </Button>
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD_EXAM}
                  rowCount={batchList.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {sortedExam.map((row) => {
                    return (
                      <TableRow hover key={get(row, '_id', '')} tabIndex={-1}>
                        <TableCell align="left"> {get(row, 'name', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'code', '')}</TableCell>

                        <TableCell align="left">
                          {/* {moment(get(row,'startTime')) > moment() ?
                        (moment(get(row,'endTime')) > moment() ? 
                        <Button style={{color: "white", backgroundColor: 'blue', marginTop: 17 }} variant="contained" >upcoming</Button> : 
                        <Button style={{color: "white", backgroundColor: '#16801A', marginTop: 17 }} variant="contained" >running</Button>) :
                        <Button style={{color: "white", backgroundColor: '#F21717', marginTop: 17 }} variant="contained" >finished</Button>
                        } */}
                          {new Date(row.startTime) >= serverDate ? (
                            <Button variant="contained" color="primary" onClick={() => {}}>
                              Upcoming
                            </Button>
                          ) : new Date(row.answerPublishTime).getTime() <= serverDate ? (
                            <Button
                              variant="contained"
                              style={{
                                background: 'red',
                                color: '#fff',
                              }}
                              onClick={() => {}}
                            >
                              Finished
                            </Button>
                          ) : new Date(row.endTime) <= serverDate ? (
                            <Button
                              variant="contained"
                              style={{
                                background: 'red',
                                color: '#fff',
                              }}
                              onClick={() => {}}
                            >
                              Finished
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              style={{ background: '#194e39', color: '#fff' }}
                              onClick={() => {}}
                            >
                              Running
                            </Button>
                          )}
                        </TableCell>

                        <TableCell align="left"> {row.enrolled}</TableCell>
                        <TableCell align="left"> {row.totalMarks}</TableCell>

                        <TableCell align="left">
                          {' '}
                          {moment(get(row, 'startTime')).format('( DD-MM-yyyy )-( hh:mm A )')}
                        </TableCell>
                        <TableCell align="left">
                          {' '}
                          {moment(get(row, 'endTime')).format('( DD-MM-yyyy )-( hh:mm A )')}
                        </TableCell>

                        {/* <TableCell align="left"> {
                          <TextField
                            id="date"
                            label="Exam End Time"
                            type="datetime-local"
                            defaultValue={moment(get(row,'endTime' , null)).format("YYYY-MM-DDTHH:mm")}
                            // type="datetime"
                            // defaultValue={moment(get(row,'startTime' , null)).format("YYYY-MM-DD HH:mm a")}
                            onChange={() => { console.log('changing date') }}
                            sx={{ width: 220 }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        }
                        </TableCell> */}

                        <TableCell align="left">
                          <Button
                            variant="contained"
                            component={RouterLink}
                            to={'/dashboard/editExam/?examId=' + get(row, '_id')}
                          >
                            EDIT
                          </Button>
                        </TableCell>

                        <TableCell align="left">
                          <Button
                            variant="contained"
                            component={RouterLink}
                            to={'/dashboard/examResult/?examId=' + get(row, '_id')}
                          >
                            VIEW
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={batchCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={10}>
          {' '}
        </Stack>
      </Container>
    </Page>
  );
}
