import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Card, CardContent, Checkbox, Typography } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';

import parse from 'html-react-parser';
// components
import Page from '../components/Page';
// sections
import { useEffect, useState } from 'react';
import { getExamSolution } from 'src/axiosLib/services/exam/user';
import { Icon } from '@iconify/react';
import { get } from 'lodash';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function UserCheckExamAnswer() {
  const smUp = useResponsive('up', 'sm');
  const navigate = useNavigate();

  const mdUp = useResponsive('up', 'md');

  const [mcqData, setmcqData] = useState({});
  const [examData, setexamData] = useState({});
  const [correctOptions, setCorrectOptions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [publishedAnser, setPublishedAnswer] = useState(false);



  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const examId = urlParams.get('examId');
    // console.log(examId);

    getExamSolution(examId, (err, data) => {
			if (!err) {
        const mcqData = data.mcqData;
				let examData = data.examDetail;
        let tempAns = [...answers];
        let tempcorrect = [...correctOptions];

        for (let i = 0; i < examData.mcqContent.length; i++) {
					tempcorrect.push(new Set(examData.mcqContent[i].correctOptions.sort()));

					// check if this exists in mcqData, if it does then get the given answers
					if (data.enrolled) {
						const mcqObj = mcqData.mcq.find((obj) => obj.mcqId._id === examData.mcqContent[i]._id);

						if (mcqObj) tempAns.push(new Set(mcqObj.givenAnswers.sort()));
						else tempAns.push(new Set());
					} else tempAns.push(new Set());
				}

        setexamData(examData);
        setAnswers(tempAns);
        setmcqData(mcqData);
        setCorrectOptions(tempcorrect);
        
        setPublishedAnswer(true);
      }else{
        // navigate('/login', { replace: true });

        alert('Solution is not available yet !');
      }

    });

  }, []);

  const determineColor = (idx, jdx) => {
		// const {answers, correctOptions} = this.state;

		// not an answer and not checked
		if (!answers[idx].has(jdx) && !correctOptions[idx].has(jdx)) return "grey";
		// wrong answer
		else if (answers[idx].has(jdx) && !correctOptions[idx].has(jdx)) return "red";
		// correct answer
		else if (answers[idx].has(jdx) && correctOptions[idx].has(jdx)) return "green";
		// not checked but an answer - either skipped or got this question wrong
		else {
			return "red";
		}
	};

  const determineIcon = (idx, jdx) => {
		// const {answers, correctOptions} = this.state;

		if (answers[idx].has(jdx) && !correctOptions[idx].has(jdx))
			return <Icon icon="mdi:cancel-box-outline" style={{fontSize: '28px'}}/>;
		else return <Icon icon="ri:checkbox-line" style={{fontSize: '28px'}} />;
	};



  return (
    <Page title="Exam Result Check">

      {publishedAnser?
      <div>
        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
          <Card sx={{width: '98%', marginBottom: '30px', border: '2px solid #800000' }}>
            <CardContent style={{backgroundColor: 'beige', color: '#800000', fontWeight: 'bold', fontSize: '20px'}}>
              <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                <Typography variant="h2">
                    {examData.name}
                  </Typography>
                  <Typography variant="h5">
                    {`Batches: ${examData.batchId.map((e) => e.batchName).join("-")}`}
                  </Typography>
                  <Typography variant="h5">
                    {`Subjects: ${examData.subjectId.map((e) => e.name).join("-")}`}
                  </Typography>
                </div>
            </CardContent>
          </Card>
        </div>
        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
          <Card sx={{width: '70%', marginBottom: '30px', border: '2px solid #800000' }}>
            <CardContent style={{backgroundColor: 'beige', color: '#800000', fontWeight: 'bold', fontSize: '20px'}}> 
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                  <Typography variant="h5">
                    <i>{`Score: ${get(mcqData, 'totalMarksGained', 0)}`}</i>
                  </Typography>
                  <Typography variant="h5">
                    <i>{`Total Marks: ${examData.totalMarks}`}</i>
                  </Typography>
                  <Typography variant="h5">
                    <i>{`Duration in Minute: ${examData.duration}`}</i>
                  </Typography>
                </div>
            </CardContent>
          </Card>
        </div>
        {examData &&
					examData.mcqContent &&
					examData.mcqContent.map((obj, idx) => (
            <div>
                <Card sx={{width: '98%', marginBottom: '30px', border: '2px solid #800000' }}>
                  <CardContent>
                    <div style={{display: "flex", width: "100%"}}>
                      <div style={{width: "10%", margin: '10px',  }}>
                        <div style={{width: 35, backgroundColor: '#800000', textAlign: 'center', borderRadius: '50%', color: 'white'}}>{idx + 1}</div>
                      </div>
                      <div style={{fontSize: '20px', width: '80%', marginTop: '5px'}}>
                        {parse(obj.question)}
                      </div>
                    </div>
                    {obj.options.map((opt, jdx) => (
                      <div style={{display: "flex", width: "100%"}}>
                        <div style={{width: "10%", margin: '10px' }}>
                          <Checkbox
                              // checked={answers[idx].has(jdx)}
                              color="primary"
                              inputProps={{"aria-label": "primary checkbox"}}
                              checked={answers[idx].has(jdx) || correctOptions[idx].has(jdx)}
                              style={{color: determineColor(idx, jdx)}}
                              checkedIcon={determineIcon(idx, jdx)}
                          />
                        </div>
                        <div style={{ width: '94%', display: 'flex', alignItems: 'center',}}>
                          {parse(opt.title)}
                        </div>
                      </div>
                    ))}
                  </CardContent>
                </Card>
              </div>
          ))}
        <div style={{marginTop: 20, display: 'flex', justifyContent: 'center'}}>
					<Button style={{margin: 10,
                            background: "green",
                            color: "#fff",
                            }} variant="contained">
						Correct: {mcqData.correct}
					</Button>
					<Button style={{margin: 10, background: 'red'}} variant="contained">
						Incorrect: {mcqData.incorrect}
					</Button>
					<Button style={{margin: 10}} variant="contained">
						Skipped: {mcqData.skipped}
					</Button>
				</div>
        <div style={{marginTop: 20, display: 'flex', justifyContent: 'center'}}>
          <Button variant="contained" component={RouterLink}
                              to= {"/user/currentExams" }
                              style={{ color: "white", backgroundColor: 'blue' }} >BACK TO HOME</Button>
        </div>
      </div>: <h1> Exam Result is in progress</h1>}
      
    </Page>
  );
}
