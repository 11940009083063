import { getReqAuth, postReqAuth } from '../../api';

// 1. Create New Branch
export const createBatch = (data, cb) => postReqAuth("/webapi/institute/batch/create", data, "", cb);

// 2. Get All Branches
export const getAllBatches = (limit, page, cb) =>
	postReqAuth("/webapi/institute/batch/get/all", {limit, page}, "", cb);

export const getBatchesPerBranch = (limit, page, branchId, cb) =>
	postReqAuth("/webapi/institute/batch/get/branch", {limit, page, branchId}, "", cb);

// 3. Get Branch Detail
export const getBatchDetail = (id, cb) => getReqAuth("/webapi/institute/batch/get/detail/", {}, id, cb);
