import axios from 'axios';
import config from '../../../util/config';
import showErr from '../../api/errorAxios';
import { getReqAuth, postReq, postReqAuth } from '../../api';
// Mock data
import users from '../../data/users';
import orders from '../../data/orders';

function lookupUser(user) {
	const userCopy = JSON.parse(JSON.stringify(user));
	const userOrders = userCopy.orders.map((id) => orders.find((order) => order.id === id));
	const userMoneySpent = userCopy.orders.reduce((total, order) => total + order.amount, 0);

	userCopy.orders = userOrders;
	userCopy.moneySpent = userMoneySpent;

	return userCopy;
}

// 1. Logging in on username LOG
// setting up mock data
export const loginUsername = (data, cb) => postReq("/webapi/student/user/login", data, "", cb, true);

export const signupUsername = (data, cb) => postReq("/webapi/student/user/register", data, "", cb);

export const signupForPublicExam = (data, cb) => postReq("/webapi/student/user/publicUser/createOrUpdate", data, "", cb);

// 2. Change password on password LOG
export const changePasswordOnPassword = (username, oldPassword, newPassword, cb) =>
	postReqAuth(
		"/api/user/auth/changepassword/username",
		{
			username,
			oldPassword,
			newPassword,
		},
		"",
		cb
	);

export const verifyUser = (id, cb) =>
	config.ENV
		? getReqAuth("/api/user/auth/verify/", {}, id, cb)
		: new Promise((resolve, reject) => {
				setTimeout(() => {
					const user = users.find((user) => user.id === id);
					if (user) {
						resolve({
							user: lookupUser(user),
						});
					} else {
						reject({
							error: "User not found",
						});
					}
				}, 500);
		  });

export const getProfile = (cb) => getReqAuth("/webapi/student/user/profile", {}, "", cb);

export const resetPassword = (phone, cb) => postReq("/webapi/student/user/changepassword/reset",phone , "", cb);

export function logout(cb) {
	const token = localStorage.getItem("jwtToken");
	if (token) {
		axios.defaults.headers.common["Authorization"] = token;
		axios
			.get(config.BASE_API_URL + "/webapi/student/user/logout")
			.then((res) => {
				localStorage.removeItem("jwtToken");
				localStorage.removeItem("user");
				localStorage.removeItem("type");
				delete axios.defaults.headers.common["Authorization"];
				cb(null, res.data);
			})
			.catch((err) => {
				showErr(err, cb);
			});
	} else {
		cb("Not authenticated", null);
	}
}

export function logoutAll(cb) {
	const token = localStorage.getItem("jwtToken");
	if (token) {
		axios.defaults.headers.common["Authorization"] = token;
		axios
			.get(config.BASE_API_URL + "/webapi/student/user/logout/all")
			.then((res) => {
				localStorage.removeItem("jwtToken");
				localStorage.removeItem("user");
				localStorage.removeItem("type");
				delete axios.defaults.headers.common["Authorization"];
				cb(null, {});
			})
			.catch((err) => {
				showErr(err, cb);
			});
	} else {
		cb("Not authenticated", null);
	}
}

// 3. User list
export const getUserList = (limit, page, cb) =>
	getReqAuth("/webapi/student/user/all", {limit, page}, "", cb);
// : new Promise((resolve) => {
// 		setTimeout(() => {
// 			const usersLookup = users.slice(0, limit).map(lookupUser);

// 			resolve({
// 				users: usersLookup,
// 				usersTotal: users.length,
// 			});
// 		}, 700);
//   });

export const verifyAdmin = (id, cb) => getReqAuth("/webapi/student/admin/verify/", {}, id, cb);

export const blockAdmin = (id, cb) => getReqAuth("/webapi/student/admin/block/", {}, id);

export const setPriviledge = (id, data, cb) =>
	postReqAuth("/webapi/student/admin/set/priviledge/", data, id, cb);
