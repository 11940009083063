import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

// material
import {
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { UserListHead } from '../sections/@dashboard/user';
import moment from 'moment';
import { getAllExams, getEnrolledExams, getExamScore } from 'src/axiosLib/services/exam/user';
// mock
// import USERLIST from '../_mock/user';

// ----------------------------------------------------------------------


const TABLE_HEAD_EXAM = [
  { id: 'name', label: 'Exam Name', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  

  { id: 'marks', label: 'Marks', alignRight: false },
  { id: 'Start Time', label: 'Start Time', alignRight: false },
  { id: 'End Time', label: 'End Time', alignRight: false },
  // { id: 'registered_at', label: 'Registered at', alignRight: false },
  // { id: 'profile', label: 'EDIT', alignRight: false },
];

const TABLE_ENROLLED_EXAM = [
  { id: 'name', label: 'Exam Name', alignRight: false },
  { id: 'status', label: 'Score', alignRight: false },
  { id: 'ans', label: 'Answer', alignRight: false },

  { id: 'marks', label: 'Marks', alignRight: false },
  { id: 'Start Time', label: 'Ans Publish Time', alignRight: false },

  // { id: 'registered_at', label: 'Registered at', alignRight: false },
  // { id: 'profile', label: 'EDIT', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}


export default function UserExamList() {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);

  const [result, setResult] = useState({});

  const [resultModal, setResultModal] = useState(false);

  const [upcomingModal, setUpcomingModal] = useState(false);

  const [enrolledPage, setEnrolledPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [enrolledRowsPerPage, setEnrolledRowsPerPage] = useState(10);

  const [examList, setExamList] = useState([]);

  const [examCount, setExamCount] = useState(0);

  const [enrolledExamList, setEnrolledExamList] = useState([]);

  const [enrolledExamCount, setEnrolledExamCount] = useState(0);

  const [upcomingModalContent, setUpcomingModalContent] = useState({});

  const [serverDate, setServerDate] = useState(new Date());


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangePageEnrolled = (event, newPage) => {
    setEnrolledPage(newPage);
  };

  const handleChangeRowsPerPageEnrolled = (event) => {
    // console.log(event.target.value)
    setEnrolledRowsPerPage(event.target.value);
    setEnrolledPage(0);
  };

  const handleChangeRowsPerPage = (event) => {
    // console.log(event.target.value)
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const getExams = (rowsPerPage, page) => {
    try {
      getAllExams(rowsPerPage, page, (error, data) => {

        if (!error) {
          // console.log(data);
          setExamList(get(data, 'exams', []));
          setExamCount(get(data, 'size', 0));
          setServerDate(new Date(get(data, 'serverTime', new Date())) ) 
        } else {
          navigate('/login', { replace: true });
        }
      }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getEnrolled = (rowsPerPage, page) => {
    try {
      getEnrolledExams(rowsPerPage, page, (error, data) => {

        if (!error) {
          // console.log(data);
          setEnrolledExamList(get(data, 'exams', []));
          setEnrolledExamCount(get(data, 'size', 0));
        } else {
          console.log(error)
        }
      }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const resultData=(examId)=>{

    getExamScore(examId, (err,data)=>{  
      if(!err){
        // console.log(data);

        setResult(data);

        setResultModal(true);
      }
    })

    // setSubjectModal(false)
  };

  const upcomingText=(row)=>{

    // console.log(row)
    setUpcomingModalContent(row);

    setUpcomingModal(true)
  };
  

  useEffect(() => {
    getExams(rowsPerPage, page);
  }, [page, rowsPerPage]);

  useEffect(() => {
    getEnrolled(enrolledRowsPerPage, enrolledPage);
  }, [ enrolledPage, enrolledRowsPerPage]);

  return (
    <Page title="Institute Details">
      <Container>


{/* Exam */}
        <Card>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
            <Typography variant="h4" gutterBottom style={{ marginLeft: 15 , color: "#FF0000", backgroundColor: 'white' }} >
              New Exams
            </Typography>
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD_EXAM}
                  rowCount={examList.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {examList.map((row) => {
                    return (
                      <TableRow
                        hover
                        key={get(row, '_id', '')}
                        tabIndex={-1} >
                        <TableCell align="left"> {get(row, 'name', '')}</TableCell>

                        <TableCell align="left">
                        {/* {moment(get(row,'startTime')) > moment() ?
                        (moment(get(row,'endTime')) > moment() ? 
                        <Button style={{color: "white", backgroundColor: 'blue', marginTop: 17 }} variant="contained" >upcoming</Button> : 
                        <Button style={{color: "white", backgroundColor: '#16801A', marginTop: 17 }} variant="contained" >running</Button>) :
                        <Button style={{color: "white", backgroundColor: '#F21717', marginTop: 17 }} variant="contained" >finished</Button>
                        } */}
                          {new Date(row.startTime) >= serverDate ? (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {upcomingText(row)}}>
                              Upcoming Exam
                            </Button>
                          ) : new Date(row.answerPublishTime).getTime() <= serverDate ? (
                            <Button
                              variant="contained"
                              style={{
                                background: "green",
                                color: "#fff",}}
                                component={RouterLink}
                                to= {"/user/checkAnswer/?examId=" + (get(row,'_id')) }>
                                Check Result
                            </Button>
                          ) : new Date(row.endTime) <= serverDate ? (
                            <Button
                              variant="contained"
                              style={{
                                background: "green",
                                color: "#fff",}}
                                component={RouterLink}
                                to= {"/user/checkAnswer/?examId=" + (get(row,'_id')) }>
                                Check Result
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              style={{background: "#800000", color: "#fff"}}
                              component={RouterLink}
                              to= {"/user/exam/participate/?examId=" + (get(row,'_id')) }>
                              participate
                            </Button>
                          )}
                        </TableCell>
                        
                        
                        {/* <TableCell align="left"> {row.enrolled}</TableCell> */}
                        <TableCell align="left"> {row.totalMarks}</TableCell>
                      
                        <TableCell align="left"> {moment(get(row,'startTime')).format("( DD-MM-yyyy )-( hh:mm A )")}</TableCell>
                        <TableCell align="left"> {moment(get(row,'endTime')).format("( DD-MM-yyyy )-( hh:mm A )")}</TableCell>
                        

                      
                          {/* <TableCell align="left">
                          <Button variant="contained" component={RouterLink}
                          to= {"/dashboard/editExam/?examId=" + (get(row,'_id')) }>
                             EDIT
                         </Button>
                          </TableCell> */}

                        {/* <TableCell align="left">
                          <Button variant="contained" component={RouterLink}
                          to= {"/user/exam/participate/?examId=" + (get(row,'_id')) }>
                             VIEW
                         </Button>
                          </TableCell> */}
                      </TableRow>
                    );
                  })}

                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={examCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={10}> </Stack>



        <Card>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
            <Typography variant="h4" gutterBottom style={{ marginLeft: 15 , color: "#FF0000", backgroundColor: 'white' }} >
              My Enrolled Exams
            </Typography>
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  // order={order}
                  // orderBy={orderBy}
                  headLabel={TABLE_ENROLLED_EXAM}
                  rowCount={enrolledExamList.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {enrolledExamList.map((row) => {
                    return (
                      <TableRow
                        hover
                        key={get(row, '_id', '')}
                        tabIndex={-1} >
                        <TableCell align="left"> {get(row, 'name', '')}</TableCell>

                        <TableCell align="left">
                        <Button variant="contained" onClick={() => resultData(get(row, '_id', ''))} 
                            style={{ color: "white", backgroundColor: 'blue' }}>
                            SCORE</Button>
                        </TableCell>

                        <TableCell align="left">
                        <Button
                              variant="contained"
                              style={{
                                background: "green",
                                color: "#fff",}}
                                component={RouterLink}
                                to= {"/user/checkAnswer/?examId=" + (get(row,'_id')) }>
                                Check Result
                            </Button>
                          </TableCell>

                        {/* <TableCell align="left">
                        {moment(get(row,'startTime')) > moment() ?
                        (moment(get(row,'endTime')) > moment() ? 
                        <Button style={{color: "white", backgroundColor: 'blue', marginTop: 17 }} variant="contained" >upcoming</Button> : 
                        <Button style={{color: "white", backgroundColor: '#16801A', marginTop: 17 }} variant="contained" >running</Button>) :
                        <Button style={{color: "white", backgroundColor: '#F21717', marginTop: 17 }} variant="contained" >finished</Button>
                        }
                        </TableCell> */}
                        
                        
                        {/* <TableCell align="left"> {row.enrolled}</TableCell> */}
                        <TableCell align="left"> {row.totalMarks}</TableCell>
                      
                        <TableCell align="left"> {moment(get(row,'answerPublishTime')).format("( DD-MM-yyyy )-( hh:mm A )")}</TableCell>
                        
                      </TableRow>
                    );
                  })}

                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={enrolledExamCount}
            rowsPerPage={enrolledRowsPerPage}
            page={enrolledPage}
            onPageChange={handleChangePageEnrolled}
            onRowsPerPageChange={handleChangeRowsPerPageEnrolled}
          />
        </Card>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={10}> </Stack>

{/* RESULT */}
        <div>
            <Dialog open={resultModal} onClose={() => setResultModal(false)}>
              <DialogTitle>Add new Subject</DialogTitle>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}> </Stack>
              <DialogContent>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField name="name" label="Score" 
                style={{width : "100%"}} 
                value={get(result, 'totalMarksGained', '')} />

                <TextField name="correct" label="Correct Answer" 
                style={{width : "100%"}} 
                value={get(result, 'correct', '')} /> 
              </Stack>

              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}> </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField name="name" label="Incorrect Answer" 
                style={{width : "100%"}} 
                value={get(result, 'incorrect', '')} />

                <TextField name="correct" label="Skipped Answer" 
                style={{width : "100%"}} 
                value={get(result, 'skipped', '')} /> 
              </Stack>
              </DialogContent>
              
              <DialogActions>
                <Button variant="contained" onClick={()=>{ setResultModal(false)}} style={{ color: "white", backgroundColor: 'blue' }} >CLOSE</Button>
              </DialogActions>
            </Dialog>

        </div>

{/* UPCOMING */}
        <div>
            <Dialog open={upcomingModal} onClose={() => setUpcomingModal(false)}>
              <DialogTitle>UPCOMING EXAM</DialogTitle>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}> </Stack>
              <DialogContent>
                <Typography>
                  Make sure you are present on time. Total marks for this exam is {upcomingModalContent.totalMarks}, exam dueation is {upcomingModalContent.duration} minutes.
                </Typography>
                <Typography variant="h5" style={{marginTop: '5px'}}>
                  {upcomingModalContent.name} exam
                </Typography>
                <Typography style={{marginTop: '5px'}}>
                  <b>{`Start Time: ${moment(upcomingModalContent.startTime).format("dddd, LL hh:mm A")}`}</b>
                </Typography>
                <Typography style={{marginTop: '5px'}}>
                  <b>{`End Time: ${moment(upcomingModalContent.endTime).format("dddd, LL hh:mm A")}`}</b>
                </Typography>
                <Typography style={{marginTop: '5px'}}>
                  There are in total {upcomingModalContent.numberOfQuestions} questions, each containing {upcomingModal.marksPerQuestion}. Negative marks {upcomingModalContent.negativeMarks} will be deducted for each wrong answer.
                </Typography>

              
              </DialogContent>
              <DialogActions>
                <Button variant="contained" onClick={()=>{ setUpcomingModal(false); setUpcomingModalContent({})}} style={{ color: "white", backgroundColor: 'blue' }} >CLOSE</Button>
              </DialogActions>
            </Dialog>

        </div>

      </Container>
    </Page>
  );
}
