import axios from 'axios';
import showErr from './errorAxios';
import config from '../../util/config';

// const tempToken = "JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI1ZWQyNjYxMTY3MWVjYTNlZGNkYTgzYmIiLCJ0b2tlbklkIjoiNjM3NzAyOGQwNGJmNDAzOGNiOGExZjQzIiwidXNlcm5hbWUiOiJyYWFzaGlkbWFoZGkxOUBnbWFpbC5jb20iLCJsZXZlbCI6MSwiaWF0IjoxNjY4NzQzODIxLCJleHAiOjE2Njg3NjE4MjF9.Tc_cJt8xkhikQqjxLlgjoP6QY6JsP44GFC0y7z3qw3M";

export const postReqAuth = (route, data, param, cb) => {
	const token = localStorage.getItem("jwtToken");

	if (token) {
		axios.defaults.headers.common.Authorization = token;
		axios
			.post(config.BASE_API_URL + route + param, data)
			.then((res) => {
				// return res;
				cb(null, res.data);
			})
			.catch((err) => {
				showErr(err, cb);
				// return err;
			});
	} else {
		cb("Not authenticated", null);
	}
};

export const postReq = (route, data, param, cb, setAuth) => {
	axios
		.post(config.BASE_API_URL + route + param, data)
		.then((res) => {
			if (setAuth) {
				axios.defaults.headers.common.Authorization = res.data.token;
				localStorage.setItem("jwtToken", res.data.token);
				localStorage.setItem("user", res.data.user);
			}
			cb(null, res.data);
		})
		.catch((err) => {
			showErr(err, cb);
		});
};

export const getReqAuth = (route, data, param, cb) => {
	const token = localStorage.getItem("jwtToken");

	if (token) {
		axios.defaults.headers.common.Authorization = token;
		axios
			.get(config.BASE_API_URL + route + param, data)
			.then((res) => {
				cb(null, res.data);
			})
			.catch((err) => {
				showErr(err, cb);
			});
	} else {
		cb("Not authenticated", null);
	}
};

export const getReq = (route, data, param, cb) => {
	axios
		.get(config.BASE_API_URL + route + param, data)
		.then((res) => {
			cb(null, res.data);
		})
		.catch((err) => {
			showErr(err, cb);
		});
};
