import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Card, Container, DialogActions, DialogContent, Stack, TextField } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
// sections

import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { getExamScore } from 'src/axiosLib/services/exam/user';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function UserAfterSubmit() {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  const [subjectModal, setSubjectModal] = useState(false);

  const [subjectData, setSubjectData] = useState();

  const [result, setResult] = useState({});


  const resultData=()=>{

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const examId = urlParams.get('examId');

    getExamScore(examId, (err,data)=>{  
      if(!err){
        // console.log(data);

        setResult(data);
      }
    })

    // setSubjectModal(false)
  };

  
  useEffect(() => {resultData();},[])


  return (
    <Page title="Exam Result Check">
      <Container maxWidth="xl" sx={{paddingTop: '20px', paddingBottom: '20px'}}>

      <h1>SUBMISSION SUCCESSFUL</h1>

      <div>
          
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}> </Stack>
              <DialogContent>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField name="name" label="Score" 
                style={{width : "100%"}} 
                value={get(result, 'totalMarksGained', '')} />

                <TextField name="correct" label="Correct Answer" 
                style={{width : "100%"}} 
                value={get(result, 'correct', '')} /> 
              </Stack>

              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}> </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField name="name" label="Incorrect Answer" 
                style={{width : "100%"}} 
                value={get(result, 'incorrect', '')} />

                <TextField name="correct" label="Skipped Answer" 
                style={{width : "100%"}} 
                value={get(result, 'skipped', '')} /> 
              </Stack>
              </DialogContent>
              
              <DialogActions>
                <Button variant="contained" component={RouterLink}
                              to= {"/user/currentExams" }
                              style={{ color: "white", backgroundColor: 'blue' }} >BACK TO HOME</Button>
              </DialogActions>
            
        </div>
        </Container>
    </Page>
  );
}
